import React, { useRef, useState} from 'react'
import VertcialCarousel from "Components/VerticalCarousel/VerticalCarousel";
import HorizontalCarousel from "Components/HorizontalCarousel/HorizontalCarousel";
import styles from '../../Containers/GalleryPage/GalleryPage.module.css';


const Gallery = ({ galleryData, galleryThumbData, data ,isActiveMenu }) => {

    const sliderMain = useRef();
    const sliderThumb = useRef();

    const [slideActive , setSlideActive] = useState(0)

	return (
        <>
            <div className={styles.mainImage} id={"mainImage"} data-activemenu={isActiveMenu}>
                <HorizontalCarousel
                    galleryData={galleryData}
                    forwardRef={sliderMain}
                    data={data}
                    isActiveMenu={isActiveMenu}
                    setSlideActive={setSlideActive}
                />
            </div>
            <div className={styles.carouselBox}>
                <VertcialCarousel
                    galleryData={galleryThumbData}
                    mainRef={sliderMain}
                    data={data}
                    forwardRef={sliderThumb}
                    slideActive={slideActive}
                />
            </div>
        </>
    );
}

export default Gallery


