import React, {useEffect, useState} from "react";
import useWindowSize from "../../Components/Helpers/useWindowSize";

import styles from './errorSite.module.css'
import translation from "../../Components/Helpers/translation";
import {Link, useParams} from "react-router-dom";
import {LoaderPage} from "../../Components/LoaderPage/loaderPage";
import ScrolledLogos from "../../Components/ScrolledLogos/scrolledLogos";
import {Helmet} from "react-helmet";

function ErrorSite() {

    const { lang } = useParams()
    const size = useWindowSize()
    const [paddingTop, setPaddingTop] = useState(0);
    const [data, setData] = useState(null);

    useEffect(() => {
        const header = document.getElementById("header")
        const scrolledLogos = document.getElementById("scrolledLogos")

        if(header && scrolledLogos && size.height) {
            if((size.height - header.clientHeight - scrolledLogos.clientHeight) >= 500) {
                setPaddingTop(size.height - header.clientHeight - scrolledLogos.clientHeight)
            } else {
                setPaddingTop(500)
            }
        }
    },[size])

    useEffect(() => {
        setTimeout(() => {
            setData([])
        },200)
    },[])

    return (
        <>
            <Helmet>
                <title>{translation.helmet.error} - {translation.helmet.main}</title>
            </Helmet>
            <LoaderPage data={data}/>
            <div className={styles.wrapper} style={{paddingTop: `${paddingTop}px`}}>
                <div className={styles.container}>
                    <div className={styles.text}>{translation.errorPage.text1}</div>
                    <div className={styles.textSmall}>{translation.errorPage.text2}</div>
                    <Link className={styles.button} to={`/${lang}`} title={translation.errorPage.button}>{translation.errorPage.button}</Link>
                </div>
                <div className={styles.textBottom} dangerouslySetInnerHTML={{__html: translation.errorPage.textBottom}}/>
            </div>
            <ScrolledLogos position={true}/>
        </>
    )

}

export default ErrorSite