import React from 'react'
import styles from '../AdvancedSearch.module.css'
import { typeOfCategoryToLink, typeOfImageToLink } from "../../../Components/Helpers/selectType";
import { Link, useLocation, useParams } from "react-router-dom";
import translation from "../../../Components/Helpers/translation";
import slug from "slug";

export const AdvancedSearchElement = (props) => {
    const { lang } = useParams()
    let location = useLocation()

    function isPmpCommposer(composer){
        if(composer.groups){
			var groups = composer.groups.map(x => x['id']);
            return groups.includes('12');
        }
        return false
    }
    function linkToCommposer(composer) {
        var slug = require('slug')
        let result =  slug(composer.name);
        return isPmpCommposer(composer) ? `https://portalmuzykipolskiej.pl/pl/osoba/${composer.id}-${result}` : ''
    }

    function linkToComposition(composer , composition) {
        var slug = require('slug')
        if(composition){
            let result =  slug(composition.title);
            return linkToCommposer(composer) ? linkToCommposer(composer)  + `/kompozycje/${composition.id}-` + result : ''
        }
		return ''
    }

    return (
        <div className={styles.box}>
            <Link to={{ pathname: `${typeOfCategoryToLink(props.item, lang)}`, state: { prevPath: location.pathname } }}
                className={styles.cover}
                onClick={props.saveScrollPosition}
                title={`${translation.wcagLabels.wyszukiwanie.goToObject} - "${!props.item ? props.standardized_title : props.item.standardized_title}"`}
                aria-label={`${translation.wcagLabels.wyszukiwanie.goToObject} - "${!props.item ? props.standardized_title : props.item.standardized_title}"`}
            ></Link>

            {!props.item ?
                <>
                    <div className={styles.img} />
                    <div className={styles.title}>{props.author_or_institution}</div>
                    <div className={styles.place}>{props.standardized_title}</div>
                    <div className={styles.signature}>{props.translation.archives.signature}: {props.item.signature}</div>
                    <div className={styles.signature}>{props.translation.archives.owners}: {props.item.owners}</div>
                    <div className={styles.bottomLine} />
                </>
                :
                <>
                    <div className={styles.img} style={{ backgroundImage: `url("${typeOfImageToLink(props.item)}")` }} />
                    <div>
                        {props.item.composers[0] && props.item.author && linkToCommposer(props.item.composers[0]) ?
                            <div className={styles.containerText}>
                                <div className={styles.title}>{props.item.author_or_institution}</div>
                                <a href={linkToCommposer(props.item.composers[0])} target={"_blank"} className={styles.link} aria-label={props.item.composers[0].name} title={props.item.composers[0].name}> </a>
                                {/* <a href={"https://portalmuzykipolskiej.pl/pl/osoba/30977-jan-nepomucen-bobrowicz"} className={styles.link} aria-label={"ok"}> </a> */}
                            </div> :
                            <div className={styles.title}>{props.item.author_or_institution}</div>
                        }
                        {/*///////////////*/}
                        {/*{props.item.composers[0] && linkToCommposer(props.item.composers[0]) ?*/}
                        {/*    <div className={styles.containerText}>*/}
                        {/*        <div className={styles.place}>{props.item.standardized_title}</div>*/}
                        {/*        <a href={linkToComposition(props.item.composers[0], props.item.compositions[0])} target={"_blank"} className={styles.link} aria-label={"not finished yet"} title={"not finished yet"}> </a>*/}
                        {/*    </div> :*/}
                        {/*    <div className={styles.place}>{props.item.standardized_title}</div>*/}
                        {/*}*/}
                        <div className={styles.place}>{props.item.standardized_title}</div>
                        {/*///////////////*/}
                        <div className={styles.signature}>{props.translation.archives.signature}: {props.item.signature}</div>
                        <div className={styles.signature}>{props.translation.archives.owners}: {props.item.owners}</div>
                    </div>
                </>
            }
        </div>
    )
}
