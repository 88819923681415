import React, { useEffect, useState } from 'react';

import { LoaderPage } from '../../Components/LoaderPage/loaderPage';
import {useParams} from "react-router-dom";

import styles from './Chopiniana.module.css';

import translation from "../../Components/Helpers/translation";
import {Helmet} from "react-helmet";
import Footer from "../Footer/Footer";


function Chopiniana() {

    const [data,setData] = useState([])

    const { lang } = useParams()

    useEffect(() => {
        fetch(`https://api-polish.musicsources.pl/page/1?lang=${lang}`)
            .then((res) => res.json())
            .then((json) => {
                setData(json);
            })
    }, [lang]);

    return (
        <>
            <Helmet>
                <title>{translation.helmet.oProjekcie} - {translation.helmet.main}</title>
            </Helmet>
            <LoaderPage data={data} scroll={false}/>
            {data ?
                <div className={styles.wrapper}>
                    <div className={styles.backgroundTitle}>
                        <h1 className={styles.mainTitle}>{translation.titles.chopiniana}</h1>
                    </div>
                    {/* <div className={styles.container} dangerouslySetInnerHTML={{__html: data.txt}}/> */}
                    <Footer/>
                </div>
                : null}
        </>
    );
}

export default Chopiniana;


