import React from 'react'
import {Link, useParams} from 'react-router-dom'
import styles from "../Archives.module.css";

const ArchivesElement = ({ article, imageUrl, translation, index, dataCount }) => {

    const { name, street, postCode, area, headline  } = article;
    let slug = require('slug')
    const {lang} = useParams()


    return (
        <div className={styles.box} key={index}>
            <img src={imageUrl} alt={article.alt_image} className={styles.img}/>
            <div className={styles.item}>
                <h2 className={styles.name}>{name}</h2>
                <div className={styles.street}>{street} {postCode} {area}</div>
                <div className={styles.counter}>{dataCount !== "" ? `${dataCount.count_items} ${translation.archives.objects} ${dataCount.count_items_images} ${translation.archives.scans}` : ""}</div>
                <div className={styles.txt} dangerouslySetInnerHTML={{__html: headline}}/>
                <div className={styles.buttonsWrapper}>
                    <Link to={`/${lang}/lokalizacje/${article.id}-${slug(name)}/szczegoly`}
                          className={styles.button}
                          title={`${translation.wcagLabels.lokalizacje.goToDetails} - "${name}"`}
                          aria-label={`${translation.wcagLabels.lokalizacje.goToDetails} - "${name}"`}
                    >{translation.archives.more}</Link>
                    <Link to={`/${lang}/lokalizacje/${article.id}-${slug(name)}`}
                          className={styles.button}
                          title={`${translation.wcagLabels.lokalizacje.goToList} - "${name}"`}
                          aria-label={`${translation.wcagLabels.lokalizacje.goToList} - "${name}"`}
                    >{translation.archives.goToCollection}</Link>
                </div>
            </div>
        </div>
    );
};

export default ArchivesElement;

	