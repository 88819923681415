import React from "react";

import styles from './scrollToTop.module.css'
import translation from "../translation";

function ScrollToTop() {

    function scrollToTopFunction(to, duration) {
        const element = document.scrollingElement || document.documentElement,
            start = element.scrollTop,
            change = to - start,
            startDate = +new Date(),
            easeInOutQuad = function(t, b, c, d) {
                t /= d/2;
                if (t < 1) return c/2*t*t + b;
                t--;
                return -c/2 * (t*(t-2) - 1) + b;
            },
            animateScroll = function() {
                const currentDate = +new Date();
                const currentTime = currentDate - startDate;
                element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
                if(currentTime < duration) {
                    requestAnimationFrame(animateScroll);
                }
                else {
                    element.scrollTop = to;
                }
            };
        animateScroll();
    }

    return (
        <button type={"button"} className={styles.scrollTopButton} onClick={()=> scrollToTopFunction(0,1000)} tabIndex ="0" title={translation.wcagLabels.footer.arrow}/>
    )
}

export default ScrollToTop