import React, { useState, useEffect } from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import { LoaderPage } from '../../Components/LoaderPage/loaderPage'

import { apiRoutesUrls } from "../../Api/ApiNames";
import { typeOfCategoryToLink , typeOfImageToLink } from '../../Components/Helpers/selectType'
import translation from "../../Components/Helpers/translation";

import styles from './MainPage.module.css';
import {Helmet} from "react-helmet";
import Footer from "../Footer/Footer";

function MainPage() {

	const [data, setData] = useState(null)
	const [data1, setData1] = useState(null)
	const history = useHistory();
	const { lang } = useParams()

	const searchHandler = () => {
		const keyword = document.getElementById("search").value

		if(keyword) {
			history.push(`/${lang}/szukaj/values?keyword=${keyword}&search_fields=[]`)
			window.location.reload(true);
		}
	}

	useEffect(() => {
		fetch(`${apiRoutesUrls.main}${apiRoutesUrls.mainPage}44?limit=5&offset=0&lang=${lang}`)
			.then((res) => res.json())
			.then((json) => {
				setData(json);
			})
		fetch(`${apiRoutesUrls.main}${apiRoutesUrls.mainPage}47?limit=4&offset=0&lang=${lang}`)
			.then((res) => res.json())
			.then((json) => {
				setData1(json);
			})
	}, [lang]);

	useEffect(() => {

		const keyDown = (event) => {
			if(event.key === "Enter") {
				searchHandler()
			}
		}

		window.addEventListener('keydown', keyDown);
		return () => window.removeEventListener('keydown', keyDown);
	})

	return (
		<>
			<Helmet>
				<title>{translation.helmet.mainPage} - {translation.helmet.main}</title>
			</Helmet>
			<LoaderPage data={data}/>
			{data ?
				<>
					<div className={styles.wrapper}>
						<div className={styles.searchWrapper}>
							<div className={styles.textBox}>
								<input className={styles.inputSearch} type={"text"} id={"search"} placeholder={translation.mainPage.placeholder} title={translation.wcagLabels.mainPage.searchInput} aria-label={translation.wcagLabels.mainPage.searchInput}/>
							</div>
							<h1 className={styles.buttonBox} onClick={searchHandler} tabIndex={0} aria-label={translation.wcagLabels.mainPage.search} title={translation.wcagLabels.mainPage.search}>{translation.mainPage.find}</h1>
						</div>
					</div>

					<div className={styles.wrapperArticle}>
						<div className={styles.container}>
							<div className={styles.highlightedLine}>
								<h2>{translation.mainPage.featured}</h2>
							</div>
							<div className={styles.highlightedBig}>
								<Link to={typeOfCategoryToLink(data[0], lang)} title={`${translation.wcagLabels.mainPage.highlighted} - "${data[0].title}"`}>
									<div className={styles.bigImage} style={{backgroundImage: `url(${typeOfImageToLink(data[0])})`}}/>
									<span>{data[0].title}</span>
								</Link>
							</div>
							<div className={styles.highlightedSmall}>
								{data[1] ?
									<Link to={typeOfCategoryToLink(data[1], lang)} aria-label={`${translation.wcagLabels.mainPage.highlighted} - "${data[1].title}"`} title={`${translation.wcagLabels.mainPage.highlighted} - "${data[1].title}"`}>
										<div className={styles.smallImage} style={{backgroundImage: `url(${typeOfImageToLink(data[1])})`}}/>
									</Link>
								:null}
								{data[2] ?
									<Link to={typeOfCategoryToLink(data[2], lang)} aria-label={`${translation.wcagLabels.mainPage.highlighted} - "${data[2].title}"`} title={`${translation.wcagLabels.mainPage.highlighted} - "${data[2].title}"`}>
										<div className={styles.smallImage} style={{backgroundImage: `url(${typeOfImageToLink(data[2])})`}}/>
									</Link>
								:null}
								{data[3] ?
									<Link to={typeOfCategoryToLink(data[3], lang)} aria-label={`${translation.wcagLabels.mainPage.highlighted} - "${data[3].title}"`} title={`${translation.wcagLabels.mainPage.highlighted} - "${data[3].title}"`}>
										<div className={styles.smallImage} style={{backgroundImage: `url(${typeOfImageToLink(data[3])})`}}/>
									</Link>
								:null}
								{data[4] ?
									<Link to={typeOfCategoryToLink(data[4], lang)} aria-label={`${translation.wcagLabels.mainPage.highlighted} - "${data[4].title}"`} title={`${translation.wcagLabels.mainPage.highlighted} - "${data[4].title}"`}>
										<div className={styles.smallImage} style={{backgroundImage: `url(${typeOfImageToLink(data[4])})`}}/>
									</Link>
								:null}
							</div>
							<div className={styles.lastAddedLine}>
								<h2>{translation.mainPage.lastAdd}</h2>
							</div>
							{data1 ?
								<div className={styles.lastAddedSmall}>
									{data1[0] ?
										<Link to={typeOfCategoryToLink(data1[0], lang)} aria-label={`${translation.wcagLabels.mainPage.lastAdd} - "${data1[0].title}"`} title={`${translation.wcagLabels.mainPage.lastAdd} - "${data1[0].title}"`}>
											<div className={styles.smallImage} style={{backgroundImage: `url(${typeOfImageToLink(data1[0])})`}}/>
										</Link>
										:null}
									{data1[1] ?
										<Link to={typeOfCategoryToLink(data1[1], lang)} aria-label={`${translation.wcagLabels.mainPage.lastAdd} - "${data1[1].title}"`} title={`${translation.wcagLabels.mainPage.lastAdd} - "${data1[1].title}"`}>
											<div className={styles.smallImage} style={{backgroundImage: `url(${typeOfImageToLink(data1[1])})`}}/>
										</Link>
										:null}
									{data1[2] ?
										<Link to={typeOfCategoryToLink(data1[2], lang)} aria-label={`${translation.wcagLabels.mainPage.lastAdd} - "${data1[2].title}"`} title={`${translation.wcagLabels.mainPage.lastAdd} - "${data1[2].title}"`}>
											<div className={styles.smallImage} style={{backgroundImage: `url(${typeOfImageToLink(data1[2])})`}}/>
										</Link>
										:null}
									{data1[3] ?
										<Link to={typeOfCategoryToLink(data1[3], lang)} aria-label={`${translation.wcagLabels.mainPage.lastAdd} - "${data1[3].title}"`} title={`${translation.wcagLabels.mainPage.lastAdd} - "${data1[3].title}"`}>
											<div className={styles.smallImage} style={{backgroundImage: `url(${typeOfImageToLink(data1[3])})`}}/>
										</Link>
										:null}
								</div>
								: null
							}
						</div>
					</div>
					<Footer/>
				</>
			: null}
		</>

	);      
}

export default MainPage;


