import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { apiRoutesUrls } from 'Api/ApiNames';

import styles from "./CollectionList.module.css";
import Loader from '../../Components/Loader/Loader';
import { LoaderPage } from '../../Components/LoaderPage/loaderPage';
import CollectionListElement from "./CollectionListElement/CollectionListElement";
import translation from "../../Components/Helpers/translation";
import {Helmet} from "react-helmet";
import Footer from "../Footer/Footer";

const CollectionList = () => {
    
    let { id, lang } = useParams();
    id = parseInt(id);

    let limit = 10;

    const [data, setData] = useState(null);
    const [more, setMore] = useState(false)
    const [moreLoading, setMoreLoading] = useState(false)
    const [owner, setOwner] = useState(null)

    const [counter, setCounter] = useState(0)
    const [historyCounter,] = useState(sessionStorage.getItem("historyCounter") ? parseInt(sessionStorage.getItem("historyCounter")) : null)

    const saveScrollPosition = () => {
        const position =  window.scrollY
        const newCounter = counter + 1
        sessionStorage.setItem('position', position.toString());
        sessionStorage.setItem('historyCounter',  newCounter.toString());
    }

    useEffect(() => {
        const position = sessionStorage.getItem('position')
        if(data && position) {
            window.scroll(0, parseInt(position))
            sessionStorage.removeItem('position')
            sessionStorage.removeItem('historyCounter')
        }
    },[data])

    useEffect(() => {
        if(counter === 0) {
            if(historyCounter > 1) {
                setCounter(historyCounter - 1)
            }
            const categoryArchivesApiUrl = `${apiRoutesUrls.collectionList}${id}?lang=${lang}&limit=${historyCounter ? historyCounter * limit : limit}&offset=${limit * counter}`;
            fetch(`${apiRoutesUrls.main}${categoryArchivesApiUrl}`)
                .then((res) => res.json())
                .then((json) => {
                    setData(json);
                    if(historyCounter) {
                        if (json.length < historyCounter * limit) {
                            setMore(false)
                        } else {
                            setMore(true)
                        }
                    } else {
                        if (json.length < limit) {
                            setMore(false)
                        } else {
                            setMore(true)
                        }
                    }
                })

            fetch(`${apiRoutesUrls.main}${apiRoutesUrls.collectionName}${id}?lang=${lang}`)
                .then((res) => res.json())
                .then((json) => {
                    setOwner(json)
                })
        }
    }, [id, counter, limit, lang, historyCounter]);

    useEffect(() => {
        if(moreLoading && more) {
            const categoryArchivesApiUrl = `${apiRoutesUrls.collectionList}${id}?lang=${lang}&limit=${limit}&offset=${counter * limit}`;
            fetch(`${apiRoutesUrls.main}${categoryArchivesApiUrl}`)
                .then((res) => res.json())
                .then((json) => {
                    setMoreLoading(false)
                    let oldData = data
                    oldData = [...oldData, ...json]
                    setData(oldData);
                    if (json.length < limit) {
                        setMore(false)
                    } else {
                        setMore(true)
                    }
                })
        }
    }, [id, counter, limit, moreLoading, more, data,lang]);

    return (
        <>
            <Helmet>
                <title>{translation.helmet.kolekcjeTitleDetails} {`"${data ? lang === 'pl' ? owner.title_pl : owner.title_en : ''}"`} - {translation.helmet.main}</title>
            </Helmet>
            <LoaderPage data={data} scroll={false}/>
            {data ?
                <div className={styles.wrapper}>
                    <div className={styles.backgroundTitle}>
                        <h1 className={styles.mainTitle}>{translation.titles.collections}</h1>
                    </div>
                    <div className={styles.containerList}>
                        <div className={styles.boxSort}>
                            <h2 className={styles.titleCollection}>
                                {lang === 'pl' ? owner.title_pl : owner.title_en} {/*({translation.collections.counts[id].ob})*/}
                            </h2>
                        </div>
                        <div className={styles.container}>
                            {data.map((collection, index) => {
                                return (
                                    <CollectionListElement
                                        key={`collection-${index}`}
                                        collection={collection}
                                        saveScrollPosition={saveScrollPosition}
                                    />
                                );
                            })}
                        </div>
                        <Loader loading={moreLoading}/>
                    </div>
                    {more ?
                        <button type={"button"} className={styles.more}
                                onClick={() => {
                                    setMoreLoading(true)
                                    setCounter(counter + 1);
                                    document.getElementsByClassName(styles.container)[0].children[counter * limit + 9].focus()
                                }}
                                title={translation.wcagLabels.kolekcje.showMore}
                        >
                            {translation.other.showMore}
                        </button>
                        : null
                    }
                    <Footer/>
                </div>
            : null}
        </>

    );
};

export default CollectionList;


