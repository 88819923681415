import React from 'react';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import styles from './RightNav.module.css'

import translation from "../Helpers/translation";

const RightNav = ({ open, setOpen }) => {

    const location = useLocation();
    const history = useHistory();
    const { lang } = useParams();

    const pushSearch = () => {
        if(location.pathname !== '/szukaj/values') {
            history.push(`/${lang}/szukaj/values?keyword=&search_fields=[]`)
        }
    }

    const updateLang = (lang) => {
        const url = window.location.href.split('/')
        let newUrl = ""

        for( let i = 0; i < url.length; i++) {
            if (i === 3) {
                if( url[4] === undefined) {
                    if(lang === "pl") {
                        newUrl = newUrl + "pl"
                    } else {
                        newUrl = newUrl + "en"
                    }
                } else {
                    if(lang === "pl") {
                        newUrl = newUrl + "pl/"
                    } else {
                        newUrl = newUrl + "en/"
                    }
                }
            } else if (i === url.length - 1){
                newUrl = newUrl + url[i]
            } else {
                newUrl = newUrl + url[i] + "/"
            }
        }
        window.location.href = newUrl;
    }

    const setBlur = (e) => {
        e.target.blur()
        document.getElementById("tabFirst").focus()
    }

    return (
        <div className={`${styles.wrapper} ${open ? styles.wrapperOpen : ""}`} onFocus={() => setOpen(true)} onBlur={() => setOpen(false)}>
            <Link to={`/${lang}`} tabIndex={0} onClick={(e) => setBlur(e)} title={translation.wcagLabels.header.mainPage}>{translation.menu.mainPage}</Link>
            <Link to={`/${lang}/lokalizacje`} tabIndex={0} onClick={(e) => setBlur(e)} title={translation.wcagLabels.header.lokalizacje}>{translation.menu.archivesPage}</Link>
            <Link to={`/${lang}/kolekcje`} tabIndex={0} onClick={(e) => setBlur(e)} title={translation.wcagLabels.header.kolekcje}>{translation.menu.collectionsPage}</Link>
            <button type={"button"} className={styles.href} onClick={(e) => {pushSearch(); setBlur(e)} } tabIndex={0} title={translation.wcagLabels.header.wyszukiwanie}>
                {translation.menu.searchPage}
            </button>
            <a href={"https://polishscores.org/"} target={"_blank"} title={translation.titleLink.polishscores} rel="noreferrer" tabIndex={0} onClick={(e) => setBlur(e)}>{translation.menu.transcriptionsPage}</a>
            <Link to={`/${lang}/o-projekcie`} tabIndex={0} onClick={(e) => setBlur(e)} title={translation.wcagLabels.header.oProjekcie}>{translation.menu.aboutPage}</Link>
            <Link to={`/${lang}/chopiniana`} tabIndex={0} onClick={(e) => setBlur(e)} title={translation.wcagLabels.header.chopiniana}>{translation.menu.chopiniana}</Link>
            <a href={`https://portalmuzykipolskiej.pl/${lang}`} target={"_blank"} title={translation.titleLink.polishmusicportal} rel="noreferrer" tabIndex={0} onClick={(e) => setBlur(e)} >{translation.menu.polishmusicportal}</a>
            <div className={styles.lang}>
                <button type={"button"} className={`${styles.box} ${window.location.href.split('/')[3] === 'en' ? styles.boxActive : ''}`} onClick={(e) => {updateLang("pl"); setBlur(e)}} tabIndex={0} title={translation.wcagLabels.header.langPL} aria-label={translation.wcagLabels.header.langPL}>PL</button>
                <button type={"button"} className={`${styles.box} ${window.location.href.split('/')[3] === 'pl' ? styles.boxActive : ''}`} onClick={(e) => {updateLang("en"); setBlur(e)}} tabIndex={0} title={translation.wcagLabels.header.langEN} aria-label={translation.wcagLabels.header.langEN}>EN</button>
            </div>
        </div>
    );
}

export default RightNav





