import React, { useRef } from 'react';

import styles from './Cookie.module.css'
import translation from "../../Components/Helpers/translation";

import PDF_pl from "../../assets/pdf/Klauzura-Informacyjna-Formularz-kontaktowy.pdf"
import PDF_en from "../../assets/pdf/Information-Clause-Contact-Form.pdf"
import {useParams} from "react-router-dom";

function Cookie (props) {

    const { lang } = useParams()
    const cookie = useRef();

    const cookieSet = () => {
        localStorage.setItem("cookieConsent", "true")
        cookie.current.classList.add(styles.cookieWrapperHide)
    }

    return (
        !localStorage.getItem("cookieConsent") ?
            <div className={`${styles.cookieWrapper} ${props.scrolledLogosClosed ? styles.cookieWrapperScrolledLogosClose : "" }`} ref={cookie}>
                <div className={styles.cookie}>
                    <span className={styles.text}>{translation.cookie.text}</span>
                    <span className={styles.textStrong}>
                        <a href={lang === "pl" ? PDF_pl : PDF_en } target={"_blank"} title={translation.titleLink.coockies} aria-label={translation.titleLink.coockies} rel={"noreferrer"}>{translation.cookie.linkText}</a>
                    </span>
                    <div className={styles.close} onClick={cookieSet} tabIndex={0}/>
                </div>
            </div>
        :null
    )
}

export default Cookie