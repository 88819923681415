import React, { useState, useEffect } from 'react';
import {useLocation, useParams} from "react-router-dom";
import { LoaderPage } from '../../Components/LoaderPage/loaderPage';
import { AdvancedSearchPopc1Element } from './AdvancedSearchPopc1Element/AdvancedSearchPopc1Elememt';
import AdvSearchPopc1 from 'Components/AdvSearchPopc1/AdvSearchPopc1';
import queryString from "query-string";

import {apiRoutesUrls} from "../../Api/ApiNames";
import translation from "../../Components/Helpers/translation";
import styles from './AdvancedSearchPopc1.module.css';
import Loader from "../../Components/Loader/Loader";
import {Helmet} from "react-helmet";
import Footer from "../Footer/Footer";

function AdvancedSearchPopc1() {

	let limit = 10;
	const location = useLocation();
	const queryParams = queryString.parse(location.search)
	const [data, setData] = useState(null)
	const [counter, setCounter] = useState(0)
	const [historyCounter,] = useState(sessionStorage.getItem("historyCounter") ? parseInt(sessionStorage.getItem("historyCounter")) : null)
	const [more, setMore] = useState(false)
	const [moreLoading, setMoreLoading] = useState(false)
	const [paramKeyword,] = useState(queryParams["keyword"]);
	const [paramCriteria,] = useState(JSON.parse(queryParams["search_fields"] ? queryParams["search_fields"] : ''));
	const { lang } = useParams();

	const saveScrollPosition = () => {
		const position =  window.scrollY
		const newCounter = counter + 1
		sessionStorage.setItem('position', position.toString());
		sessionStorage.setItem('historyCounter',  newCounter.toString());
	}

	useEffect(() => {
		const position = sessionStorage.getItem('position')
		if(data && position) {
			window.scroll(0, parseInt(position))
			sessionStorage.removeItem('position')
			sessionStorage.removeItem('historyCounter')
		}
	},[data])

	useEffect(() => {
		const buildParams = () => {
			const paramData = new FormData();
			paramData.append('data', JSON.stringify({"keyword": paramKeyword, "search_fields": paramCriteria}))
			return paramData
		}

		if (counter === 0) {
			if(historyCounter > 1) {
				setCounter(historyCounter - 1)
			}
			fetch(`${apiRoutesUrls.main}${apiRoutesUrls.searchPopc1}${limit * counter}/${historyCounter ? historyCounter * limit : limit}?lang=${lang}`, {
				method: 'POST',
				body: buildParams()
			})
				.then((res) => res.json())
				.then((json) => {
					setData(json);
					if(historyCounter) {
						if (json.length < historyCounter * limit) {
							setMore(false)
						} else {
							setMore(true)
						}
					} else {
						if (json.length < limit) {
							setMore(false)
						} else {
							setMore(true)
						}
					}
				})
		}
	}, [counter, limit, paramCriteria, paramKeyword, lang, historyCounter])

	useEffect(() => {
		if (moreLoading && more) {
			const buildParams = () => {
				const paramData = new FormData();
				paramData.append('data', JSON.stringify({"keyword": paramKeyword, "search_fields": paramCriteria}))
				return paramData
			}

			fetch(`${apiRoutesUrls.main}${apiRoutesUrls.search}${limit * counter}/${limit}?lang=${lang}`, {
				method: 'POST',
				body: buildParams()
			})
				.then((res) => res.json())
				.then((json) => {
					setMoreLoading(false)
					let oldData = data
					oldData = [...oldData, ...json]
					setData(oldData);
					if (json.length < limit) {
						setMore(false)
					} else {
						setMore(true)
					}
				})
		}
	}, [counter, limit, moreLoading, more, data, paramCriteria, paramKeyword, lang]);

	return (
		<>
			<Helmet>
				<title>{translation.helmet.wyszukiwanie} - {translation.helmet.main}</title>
			</Helmet>
			<LoaderPage data={data} scroll={false}/>
			{data ?
				<div className={styles.wrapper}>
					<div className={styles.backgroundTitle}>
						<h1 className={styles.mainTitle}>{translation.titles.search}</h1>
					</div>
					<div className={styles.containerList}>
						<AdvSearchPopc1 criterium={paramCriteria} keyword={paramKeyword}/>
						<div className={styles.container}>
							{data.length
								? data.map((item, index) => {
									return (
										<AdvancedSearchPopc1Element
											key={index}
											item={item}
											translation={translation}
											saveScrollPosition={saveScrollPosition}
										/>
									)
								})
								: <div className={styles.emptydata}>{translation.other.emptyData}</div>
							}
						</div>
						<Loader loading={moreLoading}/>
					</div>
					{more ?
						<button type={"button"} className={styles.more}
								onClick={() => {
									setMoreLoading(true)
									setCounter(counter + 1);
									document.getElementsByClassName(styles.container)[0].children[counter * limit + 9].focus()
								}}
								title={translation.wcagLabels.wyszukiwanie.showMore}
						>
							{translation.other.showMore}
						</button>
						: null
					}
					<Footer />
				</div>
				: null}
		</>
	);
}

export default AdvancedSearchPopc1;


