import React, { useEffect, useState } from 'react';
import {useHistory, useParams} from "react-router-dom";

import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';

import styles from './HorizontalCarousel.module.css';
import translation from "../Helpers/translation";

const HorizontalCarousel = ({ galleryData, forwardRef, data, isActiveMenu, setSlideActive }) => {

    const [changeX, setChangeX] = useState(0);
    const [changeY, setChangeY] = useState(0);
    const [deltaX, setDeltaX] = useState(0);
    const [deltaY, setDeltaY] = useState(0);
    const [moveActive, setMoveActive] = useState();
    const [mousePositionX, setMousePositionX] = useState(0);
    const [mousePositionY, setMousePositionY] = useState(0);
    const [zoom, setZoom] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [activeElement, setActiveElement] = useState(0)
    const history = useHistory();
    const { lang, type, id, page, children} = useParams()
    let zoomSpeed = 1.2;

    const [activeSlide, setActiveSlide] = useState(page)

    const resizeImageWheel = (e) => {

        const element = document.getElementsByClassName(styles.item)[0]

        const style =  window.getComputedStyle(element);

        var xs = (e.clientX - style.marginLeft.slice(0,-2) - changeX) / zoom,
            ys = (e.clientY - style.marginTop.slice(0,-2) - changeY) / zoom;

        const delta = (e.wheelDelta ? e.wheelDelta : -e.deltaY);

        if(delta > 0){
            setZoom(zoom * zoomSpeed)
            setChangeX(e.clientX - style.marginLeft.slice(0,-2) - xs * (zoom * zoomSpeed))
            setChangeY(e.clientY - style.marginTop.slice(0,-2) - ys * (zoom * zoomSpeed))
            setDeltaX(e.clientX - style.marginLeft.slice(0,-2) - xs * (zoom * zoomSpeed))
            setDeltaY(e.clientY - style.marginTop.slice(0,-2) - ys * (zoom * zoomSpeed))
        } else {
            if(zoom > 0.3) {
                setZoom(zoom / zoomSpeed)
                setChangeX(e.clientX - style.marginLeft.slice(0,-2) - xs * (zoom / zoomSpeed))
                setChangeY(e.clientY - style.marginTop.slice(0,-2) - ys * (zoom / zoomSpeed))
                setDeltaX(e.clientX - style.marginLeft.slice(0,-2) - xs * (zoom / zoomSpeed))
                setDeltaY(e.clientY - style.marginTop.slice(0,-2) - ys * (zoom / zoomSpeed))
            }
        }


    }

    const movingImage = e => {

        if(moveActive){
            if(e.type === 'touchmove') {
                setChangeX(e.touches[0].clientX - mousePositionX);
                setChangeY(e.touches[0].clientY - mousePositionY);
            } else {
                setChangeX(e.clientX - mousePositionX);
                setChangeY(e.clientY - mousePositionY);
            }
        }
    }

    const setInitialHandler = (x, y) => {
        setMousePositionX(x - deltaX)
        setMousePositionY(y - deltaY)
    }

    const startMovingImage = (e) => {
        e.target.classList.add(styles.drag)
        setMoveActive(true);
        if(e.type === 'touchstart') {
            setInitialHandler(e.touches[0].clientX, e.touches[0].clientY);
            document.getElementsByTagName("body")[0].style.overscrollBehavior = "contain"
        } else {
            setInitialHandler(e.clientX, e.clientY);
        }
    }

    const stopMovingImage = (e) => {
        e.target.classList.remove(styles.drag)
        setMoveActive(false);
        setDeltaX(changeX);
        setDeltaY(changeY);
        document.getElementsByTagName("body")[0].style.overscrollBehavior = "unset"
    }

    const handleZoom = () => {
        setZoom(zoom * zoomSpeed)
    }

    const handleUnZoom = () => {
        if(zoom > 0.3) {
            setZoom(zoom / zoomSpeed)
        }
    }

    const handleRotate = () => {
        setRotate(rotate + 90);
    }
    
    const centerImage = () => {

        const element = document.getElementsByClassName(styles.item)[0]

        setChangeX((element.clientWidth - element.children[0].clientWidth * zoom) / 2)
        setChangeY((element.clientHeight - element.children[0].clientHeight * zoom) / 2)
        setDeltaX((element.clientWidth - element.children[0].clientWidth * zoom) / 2)
        setDeltaY((element.clientHeight - element.children[0].clientHeight * zoom) / 2)
        setMousePositionX(0)
        setMousePositionY(0)
    }

    const move = (slider,newIndex,typeEvent) => {
        if(slider) {

            if(typeEvent === "onMove") {
                setActiveSlide(newIndex + 1)
            }

            const isActiveMenuLeft = document.getElementById("mainImage").dataset.activemenu

            if(typeEvent === "onMove") {
                if(isActiveMenuLeft === "false") {
                    history.push({ pathname: `/${lang}/lokalizacje/galeria/${type}/${id}/${newIndex + 1}${children ?`/${children}` : ''}#nomenu`})
                } else {
                    history.push({ pathname: `/${lang}/lokalizacje/galeria/${type}/${id}/${newIndex + 1}${children ?`/${children}` : ''}`})
                }
                setSlideActive(newIndex)
            }
            if(typeEvent === "onReady") {
                setSlideActive(newIndex - 1)
                if(parseInt(page) < 1 || galleryData.length < parseInt(page) || isNaN(parseInt(page)) || !/^\d+$/.test(page)) {
                    if(isActiveMenuLeft === "false") {
                        history.push(`/${lang}/lokalizacje/galeria/${type}/${id}/${1}${children ?`/${children}` : ''}#nomenu`)
                    } else {
                        history.push(`/${lang}/lokalizacje/galeria/${type}/${id}/${1}${children ?`/${children}` : ''}`)
                    }
                    window.location.reload(true);
                }
            }

            window.gtag('config', 'G-0K6CHD872P', {
                page_path: `/${lang}/lokalizacje/galeria/${type}/${id}/${newIndex + 1}${children ?`/${children}` : ''}`
            });

            const sliderId = slider.root.id
            if(typeEvent === "onReady"){
                setActiveElement(newIndex-1)
            }else{
                setActiveElement(newIndex)
            }
            
            if(newIndex + 1 < 10) {
                const slidePrev = document.getElementById(`${sliderId}-slide0${newIndex}`)
                const slide = document.getElementById(`${sliderId}-slide0${newIndex + 1}`)
                const slideNext = document.getElementById(`${sliderId}-slide0${newIndex + 2}`)
                if (slidePrev) {
                    if (!slidePrev.children[0].children[0].style.backgroundImage) {
                        slidePrev.children[0].children[0].style.backgroundImage = `url("${slidePrev.children[0].children[0].dataset.back}")`
                    }
                }
                if (slide) {
                    if (!slide.children[0].children[0].style.backgroundImage) {
                        slide.children[0].children[0].style.backgroundImage = `url("${slide.children[0].children[0].dataset.back}")`
                    }
                }
                if (slideNext) {
                    if (!slideNext.children[0].children[0].style.backgroundImage) {
                        slideNext.children[0].children[0].style.backgroundImage = `url("${slideNext.children[0].children[0].dataset.back}")`
                    }
                }
                setTimeout(() => {
                    if(slidePrev) {
                        if(slidePrev.children) {
                            slidePrev.children[1].style.opacity = 0
                            setTimeout(() => {
                                slidePrev.children[1].style.display = "none"
                            },100)
                        }
                    }
                    if(slide) {
                        if(slide.children) {
                            slide.children[1].style.opacity = 0
                            setTimeout(() => {
                                slide.children[1].style.display = "none"
                            },100)
                        }
                    }
                    if(slideNext) {
                        if(slideNext.children) {
                            slideNext.children[1].style.opacity = 0
                            setTimeout(() => {
                                slideNext.children[1].style.display = "none"
                            },100)
                        }
                    }
                },400)
            } else {
                const slidePrev = document.getElementById(`${sliderId}-slide${newIndex}`)
                const slide = document.getElementById(`${sliderId}-slide${newIndex + 1}`)
                const slideNext = document.getElementById(`${sliderId}-slide${newIndex + 2}`)
                if (slidePrev) {
                    if (!slidePrev.children[0].children[0].style.backgroundImage) {
                        slidePrev.children[0].children[0].style.backgroundImage = `url("${slidePrev.children[0].children[0].dataset.back}")`
                    }
                }
                if (slide) {
                    if (!slide.children[0].children[0].style.backgroundImage) {
                        slide.children[0].children[0].style.backgroundImage = `url("${slide.children[0].children[0].dataset.back}")`
                    }
                }
                if (slideNext) {
                    if (!slideNext.children[0].children[0].style.backgroundImage) {
                        slideNext.children[0].children[0].style.backgroundImage = `url("${slideNext.children[0].children[0].dataset.back}")`
                    }
                }
                setTimeout(() => {
                    if(slidePrev) {
                        if(slidePrev.children) {
                            slidePrev.children[1].style.opacity = 0
                            setTimeout(() => {
                                slidePrev.children[1].style.display = "none"
                            },100)
                        }
                    }
                    if(slide) {
                        if(slide.children) {
                            slide.children[1].style.opacity = 0
                            setTimeout(() => {
                                slide.children[1].style.display = "none"
                            },100)
                        }
                    }
                    if(slideNext) {
                        if(slideNext.children) {
                            slideNext.children[1].style.opacity = 0
                            setTimeout(() => {
                                slideNext.children[1].style.display = "none"
                            },100)
                        }
                    }
                },400)
            }
        }
    }

    const openDownload = () => {
        /** Slide down. */
        const container = document.getElementById("downloadWrapper")

        if(!container.classList.contains(styles.downloadWrapperActive)) {
            container.classList.add(styles.downloadWrapperActive)
            container.style.height = "auto"

            let height = container.clientHeight + "px"

            container.style.height = "0px"

            setTimeout(() => {
                container.style.height = height
            }, 0)
            /** Slide up. */
        } else {
            container.style.height = container.clientHeight + "px"
            container.style.height = "0px"

            container.addEventListener('transitionend', () => {
                container.classList.remove(styles.downloadWrapperActive)
            }, {once: true})
        }
    }

    useEffect(() => {
        const resizer = () => {
            if(document.getElementById("downloadWrapper")) {
                const container = document.getElementById("downloadWrapper")
                container.style.height = "auto"
                setTimeout(() => {
                    container.style.height = container.clientHeight + "px"
                }, 100)
            }
        }

        window.addEventListener('resize', resizer);
        return () => window.removeEventListener('resize', resizer);
    },[])

    useEffect(() => {

        const keyDown = (event) => {
            if(event.key === "ArrowRight") {
                forwardRef.current.go('>')
            } else if(event.key === "ArrowLeft") {
                forwardRef.current.go('<')
            }
        }

        window.addEventListener('keydown', keyDown);
        return () => window.removeEventListener('keydown', keyDown);
    })

    const onDoubleClick = (event) => {
        switch (event.detail) {
            case 2: {
                handleZoom()
                break;
            }
            default: {
                break;
            }
        }
    }

    useEffect(() => {
        if(isActiveMenu) {
            document.getElementsByClassName(styles.vertcialLeft)[0].classList.add(styles.vertcialLeftActive)
        } else {
            document.getElementsByClassName(styles.vertcialLeft)[0].classList.remove(styles.vertcialLeftActive)
        }
    },[isActiveMenu])

    useEffect(() => {
        if(forwardRef.current) {
            const sliderId = forwardRef.current.splide.root.id

            const slidePrev = document.getElementById(`${sliderId}-slide${activeSlide -1 >= 10 ? `${activeSlide -1}` : `0${activeSlide -1}`}`)
            const slide = document.getElementById(`${sliderId}-slide${activeSlide >= 10 ? `${activeSlide}` : `0${activeSlide}`}`)
            const slideNext = document.getElementById(`${sliderId}-slide${activeSlide + 1 >= 10 ? `${activeSlide +1}` : `0${activeSlide + 1}`}`)

            if(slide) {
                slide.children[0].style.transform = `translate(${changeX}px, ${changeY}px) scale(${zoom})`
            }
            if(slidePrev) {
                slidePrev.children[0].style.transform = `translate(${changeX}px, ${changeY}px) scale(${zoom})`
            }
            if(slideNext) {
                slideNext.children[0].style.transform = `translate(${changeX}px, ${changeY}px) scale(${zoom})`
            }
        }
    },[activeSlide, changeX, changeY, zoom])

    return(
        <>
            <Splide hasTrack={ false } aria-label="..."
                ref={forwardRef}
                onMounted={() => {move(0,parseInt(page), "onMounted")}}
                onReady={(slider) => {move(slider,parseInt(page), "onReady")}}
                onMove={(slider,newIndex) => {move(slider,newIndex, "onMove")}}
                options={{
                    type: 'fade',
                    pagination: false,
                    perPage: 1,
                    height: '100vh',
                    speed: 500,
                    lazyLoad: "nearby",
                    preloadPages: 2,
                    gap: '200px',
                    waitForTransition: true,
                    drag: false,
                    dragAngleThreshold: 0,
                    start: `${parseInt(page) - 1}`,
                    i18n: {
                        prev: translation.wcagLabels.gallery.horizontalPrev,
                        next: translation.wcagLabels.gallery.horizontalNext,
                        first : translation.wcagLabels.gallery.horizontalPrev,
                        last : translation.wcagLabels.gallery.horizontalNext,
                        slideLabel : translation.arrows.slideLabel,
                        carousel : ''
                    }
                }}
                className={styles.carousel}
            >
                <div className="splide__arrows">
                    <button className={`splide__arrow splide__arrow--prev ${styles.vertcialLeft}`}
                            onClick={() => {
                                centerImage();
                            }}
                            title={translation.wcagLabels.gallery.horizontalPrev}
                            aria-label={translation.wcagLabels.gallery.horizontalPrev}
                    />
                    <button className={`splide__arrow splide__arrow--next ${styles.verticalRight}`}
                            onClick={() => {
                                centerImage();
                            }}
                            title={translation.wcagLabels.gallery.horizontalNext}
                            aria-label={translation.wcagLabels.gallery.horizontalNext}
                    />
                </div>
                <SplideTrack>
                    {galleryData.length > 0 ? (
                        galleryData.map((item, index) => {
                            return (
                                <SplideSlide
                                     key={index}
                                     className={styles.item}
                                     onWheelCapture={resizeImageWheel}
                                     onMouseMove={event => moveActive ? movingImage(event) : null}
                                     onTouchMove={event => moveActive ? movingImage(event) : null}
                                     onMouseDown={startMovingImage}
                                     onMouseUp={stopMovingImage}
                                     onTouchStart={startMovingImage}
                                     onTouchEnd={stopMovingImage}
                                     onClick={onDoubleClick}
                                >
                                    <div className={styles.image}>
                                        <div className={styles.imageContainer}
                                             data-back={item}
                                             style={{
                                                 transform: `rotate(${rotate}deg)`,
                                             }}
                                             aria-label={`${data.source_or_owner ? `${data.source_or_owner.name} - ` : ''}${translation.wcagLabels.gallery.page} ${index + 1}`}
                                             title={`${data.source_or_owner ? `${data.source_or_owner.name} - ` : ''}${translation.wcagLabels.gallery.page} ${index + 1}`}
                                        />
                                    </div>
                                    <span className={`${styles.spinner}`}/>
                                </SplideSlide>
                            );
                        })
                    ) : null}
                </SplideTrack>
            </Splide>
            <button className={styles.rotate} onClick={handleRotate} title={translation.wcagLabels.gallery.rotate} aria-label={translation.wcagLabels.gallery.rotate}/>
            <button className={styles.zoom} onClick={handleZoom} title={translation.wcagLabels.gallery.zoom} aria-label={translation.wcagLabels.gallery.zoom}/>
            <button className={styles.unZoom} onClick={handleUnZoom} title={translation.wcagLabels.gallery.unZoom} aria-label={translation.wcagLabels.gallery.unZoom}/>
            {data.gallery ?
                data.gallery[activeElement] ?
                <>
                    {data.gallery[activeElement].img_urls || data.pdf ?
                        <div className={`${styles.downloadContainer} ${isActiveMenu ? styles.isActiveMenu : ''}`}>
                            <button className={styles.centerImage} onClick={centerImage} title={translation.wcagLabels.gallery.center} aria-label={translation.wcagLabels.gallery.center}></button>
                            <button className={styles.download} onClick={openDownload} title={translation.wcagLabels.gallery.download} aria-label={translation.wcagLabels.gallery.download}/>
                            <div className={styles.downloadWrapper} id={"downloadWrapper"}>
                                { data.gallery[activeElement].img_urls ?
                                    Object.keys(data.gallery[activeElement].img_urls).map((item, index) => {
                                        let types = ['obj']
                                        Object.keys(data.gallery[activeElement].img_urls).map((subItem, index) => {
                                            if(subItem === 'jpg') {
                                                types.push('jpg')
                                            }
                                            return null;
                                        })
                                        if(!types.includes('jpg')) {
                                            types.push('medium_size')
                                        }

                                        return (
                                            types.includes(item)
                                                ? <a href={data.gallery[activeElement].img_urls[item]} key={index} className={styles.downloadButton} rel="noreferrer">{translation.galleryPage.galleryDownload[item]}</a>
                                                : null
                                        )
                                    })
                                    : null
                                }
                                { data.repo_pdf ?
                                    <a href={data.repo_pdf} className={styles.downloadButton} rel="noreferrer">{translation.galleryPage.galleryDownload.pdf}</a>
                                    : null
                                }
                            </div>
                        </div>
                    : null}
                </>
                : null
            : null}
        </>
    )
}
export default HorizontalCarousel;