import React, { useEffect, useRef, useState } from "react";
import styles from "./advSearchCriterium.module.css";
import translation from "../Helpers/translation";
import SearchParams from "../SearchParams/searchParams";
import SlideUpDownContainer from "../Helpers/slideUpDownContainer/slideUpDownContainer";

function AdvSearchCriterium(props) {

    const moreOneRef = useRef()
    const moreTwoRef = useRef()
    const [closedListOne, setClosedListOne] = useState(true)
    const [closedListTwo, setClosedListTwo] = useState(true)

    const onFocusHandler = (event, type) => {
        if (type === 1) {
            if (closedListOne) {
                setClosedListOne(!closedListOne)
                moreOneRef.current.classList.add(styles.moreRotate)
            }
        } else {
            if (closedListTwo) {
                setClosedListTwo(!closedListTwo)
                moreTwoRef.current.classList.add(styles.moreRotate)
            }
        }
    }

    const onBlurHandle = (event, type) => {
        if (type === 1) {
            if (!closedListOne) {
                setClosedListOne(!closedListOne)
                moreOneRef.current.classList.remove(styles.moreRotate)
            }
        } else {
            if (!closedListTwo) {
                setClosedListTwo(!closedListTwo)
                moreTwoRef.current.classList.remove(styles.moreRotate)
            }
        }
    }

    const handleOpen = (type, index) => {
        if (type === 1) {
            setClosedListOne(!closedListOne)
            if (closedListOne) {
                moreOneRef.current.classList.add(styles.moreRotate)
                props.setOpenedList(index)
            } else {
                moreOneRef.current.classList.remove(styles.moreRotate)
            }
        } else {
            setClosedListTwo(!closedListTwo)
            if (closedListTwo) {
                moreTwoRef.current.classList.add(styles.moreRotate)
                props.setOpenedList(index)
            } else {
                moreTwoRef.current.classList.remove(styles.moreRotate)
            }
        }
    }

    const handleChangeCategory = (event, idText) => {
        const wrapper = document.getElementById(idText);
        let mainText = wrapper.getElementsByClassName(styles.text)[0];
        mainText.innerHTML = event.target.innerHTML
        mainText.dataset.cat = event.target.dataset.cat
    }

    useEffect(() => {
        if (props.openedList !== `${props.index}-1`) {
            setClosedListOne(true)
            moreOneRef.current.classList.remove(styles.moreRotate)
        }

        if (props.openedList !== `${props.index}-2`) {
            setClosedListTwo(true)
            moreTwoRef.current.classList.remove(styles.moreRotate)
        }
    }, [props.openedList, props.index])

    return (
        <div className={styles.inputLabelsBox} key={props.index}>
            <div className={styles.searchOptions} id={`type-${props.index}`} onClick={() => { handleOpen(1, `${props.index}-1`) }} title={translation.wcagLabels.wyszukiwanie.selectCriteria}>
                <span className={styles.text} data-cat={props.item.type}>{translation.searchPage.type[parseInt(props.item.field)]}</span>
                <div className={styles.more} ref={moreOneRef} />
                <div className={styles.optionsMenuWrapper} style={{ zIndex: 1000 - props.index }} aria-label={translation.wcagLabels.wyszukiwanie.selectCriteria}>
                    <SlideUpDownContainer closed={closedListOne} closedStart={true}>
                        <div className={styles.optionsMenu}>
                            {SearchParams.map((item, index2) => {
                                return (
                                    <button type={"button"} onFocus={(event) => onFocusHandler(event, 1)} onBlur={(event) => { onBlurHandle(event, 1) }} key={index2} className={`${styles.optionText}`} data-cat={item.value} onClick={(event) => { handleChangeCategory(event, `type-${props.index}`); props.updateCriteria(event, props.index, "type") }}>{item.name}</button>
                                )
                            })}
                        </div>
                    </SlideUpDownContainer>
                </div>

            </div>
            <div className={`${styles.searchOptions} ${styles.searchOptionsSecond}`} id={`contain-${props.index}`} onClick={() => { handleOpen(2, `${props.index}-2`) }} title={translation.wcagLabels.wyszukiwanie.selectOperator}>
                <span className={styles.text} data-cat={props.item.contains}>{translation.searchPage.contain[parseInt(props.item.contains)]}</span>
                <div className={styles.more} ref={moreTwoRef} />
                <div className={styles.optionsMenuWrapper} style={{ zIndex: 1000 - props.index - 1 }} aria-label={translation.wcagLabels.wyszukiwanie.selectOperator}>
                    <SlideUpDownContainer closed={closedListTwo} closedStart={true}>
                        <div className={`${styles.optionsMenu} ${styles.optionsMenuSecond}`}>
                            <button type={"button"} onFocus={(event) => onFocusHandler(event, 2)} onBlur={(event) => { onBlurHandle(event, 2) }} className={`${styles.optionText}`} data-cat={"1"} onClick={(event) => { handleChangeCategory(event, `contain-${props.index}`); props.updateCriteria(event, props.index, "contain") }}>{translation.searchPage.contain[1]}</button>
                            <button type={"button"} onFocus={(event) => onFocusHandler(event, 2)} onBlur={(event) => { onBlurHandle(event, 2) }} className={`${styles.optionText}`} data-cat={"2"} onClick={(event) => { handleChangeCategory(event, `contain-${props.index}`); props.updateCriteria(event, props.index, "contain") }}>{translation.searchPage.contain[2]}</button>
                            <button type={"button"} onFocus={(event) => onFocusHandler(event, 2)} onBlur={(event) => { onBlurHandle(event, 2) }} className={`${styles.optionText}`} data-cat={"3"} onClick={(event) => { handleChangeCategory(event, `contain-${props.index}`); props.updateCriteria(event, props.index, "contain") }}>{translation.searchPage.contain[3]}</button>
                        </div>
                    </SlideUpDownContainer>
                </div>
            </div>
            <div className={styles.btn}>
                <input
                    className={styles.addCriterium}
                    type="text"
                    placeholder={translation.searchPage.criteriaPlaceholder}
                    onChange={(event) => props.updateCriteria(event, props.index, "keyword")}
                    value={props.item.field_text}
                    onKeyUp={props.searchOnKeyEnter}
                    title={translation.wcagLabels.wyszukiwanie.criteriaText}
                />
            </div>
        </div>
    )
}

export default AdvSearchCriterium