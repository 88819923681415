import { apiRoutesUrls } from "./ApiNames";

	export const getDataApi = (apiAddress, updatingFunction) => {
        fetch(`${apiRoutesUrls.main}${apiAddress.replace(/\s/g, "")}`)
            .then((res) => res.json())
            .then((json) => {
                updatingFunction(json);
            })
	};

    export const getDataApi2 = (apiAddress, updatingFunction) => {
        fetch(`${apiRoutesUrls.main2}${apiAddress.replace(/\s/g, "")}`)
            .then((res) => res.json())
            .then((json) => {
                updatingFunction(json);
            })
    };

    export const getDataApiMore = (apiAddress, updatingFunction, updateMore) => {
        fetch(`${apiRoutesUrls.main}${apiAddress.replace(/\s/g, "")}`)
            .then((res) => res.json())
            .then((json) => {
                updatingFunction(json);
                if(json.length < 20) {
                    updateMore(false)
                } else {
                    updateMore(true)
                }
            })
    };

    export const getDataCms = (apiAddress, updatingFunction) => {
        fetch(`${apiRoutesUrls.cms}${apiAddress.replace(/\s/g, "")}`)
            .then((res) => res.json())
            .then((json) => {
                updatingFunction(json);
            });
    };

    export const getCategoryImage = (apiAddress, updatingFunction) => {
        fetch(
            `${apiRoutesUrls.cmsCategoryArchivesImage}${apiAddress.replace(/\s/g,"")}`)
            .then((res) => res.json())
            .then((json) => {
                updatingFunction(json);
            });
    };