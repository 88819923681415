import translation from "./translation";

let slug = require('slug')

export const typeOfCategoryToLink = (item, lang , children) => {
    switch (item.type) {
        case "manuscripts":
            return `/${lang}/lokalizacje/galeria/${translation.typeToUrl[item.type]}/${item.id_object}${item.title ? `-${slug(item.title)}` : '' }/1${children ? `/${children}` : ''}`;
        case "convolutes":
            return `/${lang}/lokalizacje/galeria/${translation.typeToUrl[item.type]}/${item.id_object}${item.title ? `-${slug(item.title)}` : '' }/1${children ? `/${children}` : ''}`;
        case "musicalprint_source":
            return `/${lang}/lokalizacje/galeria/${translation.typeToUrl[item.type]}/${item.id_object}${item.title ? `-${slug(item.title)}` : '' }/1${children ? `/${children}` : ''}`;
        default:
            return "/";
    }
};

export const typeOfImageToLink = (item) => {
    switch (item.type) {
        case "manuscripts":
            return `https://storage.nifc.pl/web_files/_plik/museum/${item.type}/${item.id_object}/medium/${item.image}`;
        case "convolutes":
            return `https://storage.nifc.pl/web_files/_plik/${item.type}/${item.id_object}/medium/${item.image}`;
        case "musicalprint_source":
            return `https://storage.nifc.pl/web_files/_plik/first_edition/${item.id_object}/medium/${item.image}`;
        default:
            return null;
    }
};



