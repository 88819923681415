import React, { useRef } from "react";
import styles from "./helpModal.module.css";
import translation from "../Helpers/translation";

function HelpModal(props) {

    const modalBackgroundRef = useRef();
    const modalRef = useRef();
    const modalContainer = useRef();
    const closeRef = useRef();

    const hideModal = (event) => {
        if(event.target === modalRef.current || event.target === closeRef.current) {
            props.setOpened(!props.opened)
        }
    }

    return (
        <>
            <div ref={modalBackgroundRef} className={`${styles.modalBackground} ${!props.opened ? styles.modalBackgroundHidden : ''}`} data-opened={props.opened} />
            <div ref={modalRef} className={`${styles.modalWrapper} ${!props.opened ? styles.modalWrapperHidden : ''}`} data-opened={props.opened} onClick={hideModal}>
                <div className={styles.modalContainer} ref={modalContainer}>
                    <div className={styles.modal}>
                        <h2 className={styles.title} dangerouslySetInnerHTML={{__html: translation.searchPage.modal.title}}/>
                        <div className={styles.boxHelp}>
                            <div className={styles.buttonHelp}>{translation.searchPage.modal.description.title}</div>
                            <p className={styles.boxHelpText}>
                                <strong>{translation.searchPage.modal.description.title}</strong> - <span dangerouslySetInnerHTML={{__html: translation.searchPage.modal.description.text}}/>
                            </p>
                        </div>
                        <h3 className={styles.subTitle} dangerouslySetInnerHTML={{__html: translation.searchPage.modal.subTitle}}></h3>
                        {translation.searchPage.modal.points.map((item, index) => {
                            return (
                                <p key={index} className={styles.text}>
                                    <strong>{item.title}</strong> - <span dangerouslySetInnerHTML={{__html: item.text}}/>
                                </p>
                            )
                        })}
                        <button type={"button"} ref={closeRef} className={styles.close} onClick={hideModal}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HelpModal