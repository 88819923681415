import React from 'react';
import {useParams} from "react-router-dom";

import logoFundusze from '../../assets/image/footer/fundusze.png';
import logoFundusze_en from '../../assets/image/footer/fundusze_en.png';
import logoPolska from '../../assets/image/footer/polska.png';
import logoPolska_en from '../../assets/image/footer/polska_en.png';
import logoMinisterstwo from '../../assets/image/footer/MKiDN_pl_2023.png';
import logoMinisterstwo_en from '../../assets/image/footer/MKiDN_en_2023.png';
import logoUe from '../../assets/image/footer/UE-PL.svg';
import logoUe_en from '../../assets/image/footer/UE-EN.svg';
import styles from './Footer.module.css';
import translation from "../../Components/Helpers/translation";
import ScrollToTop from "../../Components/Helpers/ScrollToTop/scrollToTop";
import ScrolledLogos from "../../Components/ScrolledLogos/scrolledLogos";
import Social from "../Social/Social";


function Footer() {

    const { lang } = useParams()

	return (
        <>
            <Social />
            <div className={styles.wrapper} id={"footer"}>
                <div role="img" className={styles.img1} style={{backgroundImage: `url("${lang === "pl" ? logoFundusze : logoFundusze_en}")`}} aria-label={translation.wcagLabels.footer.img1}/>
                <div role="img" className={styles.img2}  style={{backgroundImage: `url("${lang === "pl" ? logoPolska : logoPolska_en}")`}} aria-label={translation.wcagLabels.footer.img2}/>
                <a href={"https://www.gov.pl/web/kultura/"} target={"_blank"} title={translation.titleLink.mkidn} rel="noreferrer" aria-label={translation.aria.footer} tabIndex={0}><div className={styles.img3}  style={{backgroundImage: `url("${lang === "pl" ? logoMinisterstwo : logoMinisterstwo_en}")`}}/></a>
                <div role="img" className={styles.img4}  style={{backgroundImage: `url("${lang === "pl" ? logoUe : logoUe_en}")`}} aria-label={translation.wcagLabels.footer.img4}/>
                <ScrollToTop/>
                <ScrolledLogos/>
            </div>
        </>
	);      
}

export default Footer;


