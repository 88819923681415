import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Splide, SplideSlide, SplideTrack} from "@splidejs/react-splide";

import { LoaderPage } from "../../Components/LoaderPage/loaderPage";

import translation from "../../Components/Helpers/translation";
import styles from "./ArchivesShow.module.css";

import {apiRoutesUrls} from "../../Api/ApiNames";
import slug from "slug";
import {Helmet} from "react-helmet";
import Footer from "../Footer/Footer";

const ArchiveShow = () => {
    const [ data, setData ] = useState(null);
    const { lang, id } = useParams();

    useEffect(() => {

        const idPure = parseInt(id)

        fetch(`${apiRoutesUrls.main}${apiRoutesUrls.source_or_owner}/${apiRoutesUrls.get_source_or_owner_by_id}/${idPure}/?lang=${lang}`)
            .then((res) => res.json())
            .then((json) => {
                setData(json)
            })
    }, [lang, id]);

    return (
        <>
            <Helmet>
                <title>{translation.helmet.lokalizacjeTitleDetails} {`"${data ? data.name : ''}"`} - {translation.helmet.main}</title>
            </Helmet>
            <LoaderPage data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <div className={styles.backgroundTitle}>
                        <h1 className={styles.mainTitle}>{translation.titles.archives}</h1>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.titleWrapper}>
                            <div className={styles.titleSubWrapper}>
                                <img src={data.image_url} alt={data.alt_image} className={styles.image} title={data.alt_image}/>
                                <h2 className={styles.title}>{data.name}</h2>
                            </div>
                            {data.headline ?
                                <div className={styles.subTitle}>{data.headline}</div>
                                : null}
                        </div>
                        {data.gallery ?
                            data.gallery.length ?
                                <div className={styles.gallery}>
                                    <Splide hasTrack={ false } aria-label="..."
                                            options={{
                                                perPage: 1,
                                                perMove: 1,
                                                type: 'fade',
                                                height: '100%',
                                                width: '750px',
                                                speed: 500,
                                                pagination: false,
                                                lazyLoad: "nearby",
                                                preloadPages: 1,
                                                updateOnMove : true,
                                                rewind: true,
                                                drag: true,
                                                breakpoints: {
                                                    1300: {
                                                        width: '880px',
                                                    },
                                                },
                                                i18n: {
                                                    prev: translation.arrows.prev,
                                                    next: translation.arrows.next,
                                                    first : translation.arrows.first,
                                                    last : translation.arrows.last,
                                                    slideLabel : translation.arrows.slideLabel
                                                }
                                            }}
                                            className={"carousel"}
                                    >
                                        <div className="splide__arrows">
                                            <button className={`splide__arrow splide__arrow--prev ${styles.arrowLeft}`} aria-label={translation.arrows.prev} title={translation.wcagLabels.lokalizacje.prevPhoto}/>
                                            <button className={`splide__arrow splide__arrow--next ${styles.arrowRight}`} aria-label={translation.arrows.next} title={translation.wcagLabels.lokalizacje.nextPhoto}/>
                                        </div>
                                        <SplideTrack>
                                            {data.gallery.map((item, index) => {

                                                return (
                                                    <SplideSlide className={styles.item} key={index}>
                                                        <img
                                                            data-splide-lazy={item.file_url}
                                                            alt={item.alt !== '' ? item.alt : " " }
                                                        />
                                                        <div className={styles.galleryText} dangerouslySetInnerHTML={{__html: item.description}}/>
                                                    </SplideSlide>
                                                )
                                            })}
                                        </SplideTrack>
                                    </Splide>
                                </div>
                                : null
                            : null }
                        <div className={styles.text} dangerouslySetInnerHTML={{__html: data.txt}}/>
                        <div className={styles.buttonWrapper}>
                            <Link to={`/${lang}/lokalizacje/${data.id}-${slug(data.name)}`} className={styles.button}
                                  title={`${translation.wcagLabels.lokalizacje.goToList} - "${data.name}"`}
                                  aria-label={`${translation.wcagLabels.lokalizacje.goToList} - "${data.name}"`}
                            >{translation.archives.goToCollection}</Link>
                        </div>
                    </div>
                    <Footer/>
                </div>
                : null
            }
        </>
    )
}

export default ArchiveShow