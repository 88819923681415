function buttonEventOnOffers (container, styleContent) {

    if(!container.classList.contains(styleContent)) {
        container.classList.add("transition")
        container.classList.add(styleContent)
        container.style.height = "auto"

        let height = container.clientHeight + "px"

        container.style.height = "0px"

        setTimeout(() => {
            container.style.height = height

            container.addEventListener('transitionend', () => {
                container.style.height = "auto"
                container.classList.remove("transition")
            }, {once: true})
        }, 0)
        /** Slide up. */
    } else {
        if(!container.classList.contains("transition")) {
            container.style.height = container.clientHeight + "px"
            setTimeout(() => {
                container.style.height = "0px"
            },0)

            setTimeout(() => {
                container.addEventListener('transitionend', () => {
                    container.classList.remove(styleContent)
                }, {once: true})
            },0)
        } else {
            container.style.height = "0px"

            container.addEventListener('transitionend', () => {
                container.classList.remove(styleContent)
            }, {once: true})
        }
    }
}

export default buttonEventOnOffers;