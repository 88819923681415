import React, { useState } from 'react';
import styles from './Burger.module.css'
import RightNav from './RightNav';

const Burger = () => {

	const [open, setOpen] = useState(false)

	return (
		<>
			<div className={`${styles.burger} ${open ? styles.burgerOpen : ''}`} onClick={() => setOpen(!open)} tabIndex={0}>
				<div />
				<div />
				<div />
			</div>
			<RightNav open={open} setOpen={setOpen}/>
		</>
	)
}

export default Burger
