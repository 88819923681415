import React from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import styles from './Menu.module.css';
import HamburgerMenu from '../HamburgerMenu/Burger';

import translation from "../Helpers/translation";

function Menu() {

    const location = useLocation();
    const history = useHistory();
    const { lang } = useParams();

    const pushSearch = () => {
        if (location.pathname !== '/szukaj/values') {
            history.push(`/${lang}/szukaj/values?keyword=&search_fields=[]`)
        }
    }

    const updateLang = (lang) => {
        const url = window.location.href.split('/')
        let newUrl = ""

        for (let i = 0; i < url.length; i++) {
            if (i === 3) {
                if (url[4] === undefined) {
                    if (lang === "pl") {
                        newUrl = newUrl + "pl"
                    } else {
                        newUrl = newUrl + "en"
                    }
                } else {
                    if (lang === "pl") {
                        newUrl = newUrl + "pl/"
                    } else {
                        newUrl = newUrl + "en/"
                    }
                }
            } else if (i === url.length - 1) {
                newUrl = newUrl + url[i]
            } else {
                newUrl = newUrl + url[i] + "/"
            }
        }
        window.location.href = newUrl;
    }

    const url = location.pathname.split('/')[2]

    const skipLink = () => {
        document.getElementById("maincontent").focus()
    }

    return (
        <div className={styles.wrapper} id={"header"} role="navigation">
            <div className={styles.leftMenu}>
                <button className={styles.skipNav} onClick={skipLink}>{translation.skipLink}</button>
                <a className={styles.nifc} id={"tabFirst"} href={"https://nifc.pl/"} target={"_blank"} title={translation.titleLink.nifc} rel="noreferrer" aria-label={translation.aria.header.logoNifc} tabIndex={0}>
                    <div className={`${lang === "pl" ? styles.logoPL : styles.logoEN}`} />
                </a>
                <Link to={`/${lang}`} aria-label={translation.aria.header.logoDMP} title={translation.aria.header.logoDMP} tabIndex={0}>
                    <div className={`${lang === "pl" ? styles.dmpPL : styles.dmpEN}`} />
                </Link>
            </div>
            {url !== "error" ?
                <div className={styles.lang}>
                    <button type={"button"} className={`${styles.box} ${window.location.href.split('/')[3] === 'en' ? styles.boxActive : ''}`} onClick={() => updateLang("pl")} tabIndex={0} title={translation.wcagLabels.header.langPL} aria-label={translation.wcagLabels.header.langPL} >PL</button>
                    <button type={"button"} className={`${styles.box} ${window.location.href.split('/')[3] === 'pl' ? styles.boxActive : ''}`} onClick={() => updateLang("en")} tabIndex={0} title={translation.wcagLabels.header.langEN} aria-label={translation.wcagLabels.header.langEN} >EN</button>
                </div>
                : null
            }
            {url !== "error" ?
                <div className={styles.rightMenu}>
                    <div className={`${styles.item} ${url === undefined ? styles.active : ''} `}>
                        <Link to={`/${lang}`} tabIndex={0} title={translation.wcagLabels.header.mainPage}>{translation.menu.mainPage}</Link>
                    </div>
                    <div className={`${styles.item} ${url === 'lokalizacje' ? styles.active : ''} `}>
                        <Link to={`/${lang}/lokalizacje`} tabIndex={0} title={translation.wcagLabels.header.lokalizacje}>{translation.menu.archivesPage}</Link>
                    </div>
                    <div className={`${styles.item} ${url === 'kolekcje' ? styles.active : ''} `}>
                        <Link to={`/${lang}/kolekcje`} tabIndex={0} title={translation.wcagLabels.header.kolekcje} >{translation.menu.collectionsPage}</Link>
                    </div>
                    <div className={`${styles.item} ${url === 'szukaj' ? styles.active : ''}`}>
                        <button type={"button"} tabIndex={0} title={translation.wcagLabels.header.wyszukiwanie} onClick={pushSearch} className={styles.itemDiv}>{translation.menu.searchPage}</button>
                    </div>
                    <div className={styles.item}>
                        <a href={"https://polishscores.org/"} target={"_blank"} title={translation.titleLink.polishscores} rel="noreferrer" tabIndex={0} >{translation.menu.transcriptionsPage}</a>
                    </div>
                    <div className={`${styles.item} ${url === 'o-projekcie' ? styles.active : ''}`}>
                        <Link to={`/${lang}/o-projekcie`} tabIndex={0} title={translation.wcagLabels.header.oProjekcie}>{translation.menu.aboutPage}</Link>
                    </div>
                </div>
                : null
            }
            {url !== "error" ?
                <HamburgerMenu />
                : null
            }
            <div id={"maincontent"} tabIndex={0}></div>
        </div>
    );
}

export default Menu;