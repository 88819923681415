import React from 'react';
import {Link, useParams} from "react-router-dom";
import styles from './Social.module.css';
import translation from "../../Components/Helpers/translation";

import facebookImg from '../../assets/image/social/facebook.png';
import instagramImg from '../../assets/image/social/instagram.png';
import youtubeImg from '../../assets/image/social/youtube.png';
import twitterImg from '../../assets/image/social/twitter.png';


function Social() {

	const { lang } = useParams();

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<div className={styles.subContainer}>
					<div className={styles.copyright}>{translation.social}</div>
					<Link className={styles.link} to={`/${lang}/deklaracja-dostepnosci`} title={translation.wcagLabels.footer.deklaracja}>{translation.availabilityDeclaration.titles["1"]}</Link>
					<Link className={styles.link} to={`/${lang}/polityka-prywatnosci`} title={translation.wcagLabels.footer.politykaPrywatnosci}>{translation.availabilityDeclaration.titles["2"]}</Link>
				</div>
				<div className={styles.socialBox}>
					<a href="https://www.facebook.com/Narodowy-Instytut-Fryderyka-Chopina-1570915943228919/" target={"_blank"} title={translation.titleLink.fb} rel="noreferrer" aria-label={translation.aria.social.fb}><div className={styles.image} style={{backgroundImage: `url("${facebookImg}")`}}/></a>
					<a href="https://www.instagram.com/chopininstitute/" target={"_blank"} title={translation.titleLink.ig} rel="noreferrer" aria-label={translation.aria.social.ig}><div className={styles.image} style={{backgroundImage: `url("${instagramImg}")`}}/></a>
					<a href="https://www.youtube.com/channel/UCSTXol20Q01Uj-U5Yp3IqFg" target={"_blank"} title={translation.titleLink.yt} rel="noreferrer" aria-label={translation.aria.social.yt}><div className={styles.image} style={{backgroundImage: `url("${youtubeImg}")`}}/></a>
					<a href="https://twitter.com/chopininstitute" target={"_blank"} title={translation.titleLink.tw} rel="noreferrer" aria-label={translation.aria.social.tw}><div className={styles.image} style={{backgroundImage: `url("${twitterImg}")`}}/></a>
				</div>

			</div>
		</div>
	);      
}

export default Social;


