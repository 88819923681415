import React, { useState } from 'react';

import styles from './AdvSearchPopc1.module.css';
import translation from "../Helpers/translation";
import queryString from "query-string";
import {useHistory, useParams} from "react-router-dom";
import HelpModal from "../HelpModal/helpModal";
import AdvSearchCriterium from "../AdvSearchCriterium/advSearchCriterium";

function AdvSearchPopc1(props) {

    const [criteria, setCriteria] = useState([...props.criterium, {"field" : 4, "contains" : 1 , "field_text": ''}]);
    const [keyword, setKeyword] = useState(props.keyword ? props.keyword : "")
    const history = useHistory();
    const { lang } = useParams();
    const [modalOpened, setModalOpened] = useState(false)
    const [openedList, setOpenedList] = useState(null)

    const updateKeyWord = (e) => {
        setKeyword(e.target.value)
    }

    const addNewCriteria = () => {
        setCriteria((prev => {
            let criteriaNew = {"field" : 4, "contains" : 1 , "field_text": ''}
            return [...prev, criteriaNew];
        }))
    }

    const updateCriteria = (event, elementId, type) => {
        setCriteria((prev => {
            let valuesTemp = prev;

            if(type === "type") {
                valuesTemp[elementId] = {
                    "field" : parseInt(event.target.dataset.cat),
                    "contains" : prev[elementId].contains ,
                    "field_text": prev[elementId].field_text
                }
            } else if (type === "contain") {
                valuesTemp[elementId] = {
                    "field" : prev[elementId].field,
                    "contains" : parseInt(event.target.dataset.cat) ,
                    "field_text": prev[elementId].field_text
                }
            } else if (type === "keyword") {
                valuesTemp[elementId] = {
                    "field" : prev[elementId].field,
                    "contains" : prev[elementId].contains ,
                    "field_text": event.target.value
                }
            }

            return [...valuesTemp];
        }))
    }

    const cleanCriteria = () => {
        setCriteria([{"field" : 4, "contains" : 1 , "field_text": ''}])
    }

    const search = () => {
        let search_fieldsTmp = criteria
        let search_fields = []

        search_fieldsTmp.forEach((item,index) => {
            if(item.field_text !== '') {
                search_fields.push(item)
            }
        })
        search_fields = JSON.stringify(search_fields)

        history.push(`/${lang}/szukajpopc1/values?keyword=${keyword}&${queryString.stringify({search_fields}, {arrayFormat: 'bracket'})}`)
        window.location.reload(true);
    }

    const searchOnKeyEnter = (event) => {
        if(event.key === "Enter") {
            search()
        }
    }

	return (
        <>
            <div className={styles.containers}>
                <div className={styles.inputSearchBox}>
                    <input className={styles.inputSearch} type="text" id="keyword" placeholder={translation.searchPage.mainPlaceholder} onChange={updateKeyWord} value={keyword} onKeyUp={searchOnKeyEnter}/>
                </div>
                <div className={styles.buttonsBox}>
                    <button type={"button"} className={styles.brownBtn} onClick={addNewCriteria} title={translation.wcagLabels.wyszukiwanie.addCriteria}>
                        <span>{translation.searchPage.addCriteria}</span>
                    </button>
                    <button type={"button"} className={styles.helpButton} onClick={() => setModalOpened(!modalOpened)} title={translation.wcagLabels.wyszukiwanie.helpButton}>
                        <span>{translation.searchPage.help}</span>
                    </button>
                </div>
                {criteria.map((item, index) => {
                    return (
                        <AdvSearchCriterium
                            key={index}
                            index={index}
                            item={item}
                            criteria={criteria}
                            updateCriteria={updateCriteria}
                            searchOnKeyEnter={searchOnKeyEnter}
                            setOpenedList={setOpenedList}
                            openedList={openedList}
                        />
                    )
                })}
                <div className={styles.buttonsFindBox}>
                    <button tabIndex={0} className={styles.greyBtn} onClick={cleanCriteria} title={translation.wcagLabels.wyszukiwanie.changeCriteria} aria-label={translation.wcagLabels.wyszukiwanie.changeCriteria}>
                        <span>{translation.searchPage.changeCriteria}</span>
                    </button>
                    <button tabIndex={0} className={styles.blueBtn} onClick={search} title={translation.wcagLabels.wyszukiwanie.search} aria-label={translation.wcagLabels.wyszukiwanie.search}>
                        <span>{translation.searchPage.search}</span>
                    </button>
                </div>
                <div className={styles.bottomBlackline}/>
                <h2 className={styles.results}>{translation.searchPage.results}</h2>
                <div className={styles.bottomBlackline}/>
            </div>
            <HelpModal opened={modalOpened} setOpened={setModalOpened}/>
        </>
    );
}

export default AdvSearchPopc1;