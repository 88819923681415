import {getDataApi, getDataApi2} from 'Api/ApiHelpers';
import { apiRoutesUrls } from 'Api/ApiNames';
import React, { useEffect, useState } from 'react';

import styles from "./Archives.module.css";
import ArchivesElement from './ArchivesElement/ArchivesElement';
import {LoaderPage} from "../../Components/LoaderPage/loaderPage";

import translation from "../../Components/Helpers/translation";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import Footer from "../Footer/Footer";

export const Archives = () => {

	const [data, setData] = useState(null);
    const [dataCount, setDataCount] = useState(null)

    const {lang} = useParams()

	useEffect(() => {
        const categoryArchivesApiUrl = `${ apiRoutesUrls.source_or_owner} / ${apiRoutesUrls.search_sources_or_owners }?lang=${lang}`;
        getDataApi(categoryArchivesApiUrl, setData);
    }, [lang]);

    useEffect(() => {
        const categoryArchivesCountApiUrl = `${ apiRoutesUrls.source_or_owner} / ${apiRoutesUrls.count_sources_or_owners_items }`;
        getDataApi2(categoryArchivesCountApiUrl, setDataCount);
    }, [lang]);

	return (
        <>
            <Helmet>
                <title>{translation.helmet.lokalizacje} - {translation.helmet.main}</title>
            </Helmet>
            <LoaderPage data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <div className={styles.backgroundTitle}>
                        <h1 className={styles.mainTitle}>{translation.titles.archives}</h1>
                    </div>
                    <div className={styles.container}>
                        {data.map((article, index) => {
                            return (
                                <div key={index}>
                                    <ArchivesElement
                                        key={index}
                                        translation={translation}
                                        article={article}
                                        imageUrl={`${apiRoutesUrls.cmsCategoryArchivesImage}/${article.id}/${article.image}`}
                                        dataCount={dataCount? dataCount[article.id] : ""}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <Footer/>
                </div>
            : null }
        </>
    );      
}

export default Archives;


