import translation from "../Helpers/translation";

function SearchParams () {

    return [
        {"name": translation.searchPage.type[4], "value": "4"},
        {"name": translation.searchPage.type[5], "value": "5"},
        {"name": translation.searchPage.type[6], "value": "6"},
        {"name": translation.searchPage.type[8], "value": "8"},
        {"name": translation.searchPage.type[10], "value": "10"},
        /*{"name": translation.searchPage.type[12], "value": "12"},*/
        {"name": translation.searchPage.type[14], "value": "14"},
        {"name": translation.searchPage.type[15], "value": "15"},
        {"name": translation.searchPage.type[16], "value": "16"},
        {"name": translation.searchPage.type[18], "value": '18'},
        {"name": translation.searchPage.type[19], "value": '19'},
        {"name": translation.searchPage.type[22], "value": '22'},
        {"name": translation.searchPage.type[23], "value": '23'},
        {"name": translation.searchPage.type[24], "value": '24'},
        {"name": translation.searchPage.type[26], "value": '26'},
        {"name": translation.searchPage.type[30], "value": '30'},
        {"name": translation.searchPage.type[50], "value": '50'},
    ]
}

export default SearchParams()