import React from 'react';

import translation from "../../Components/Helpers/translation";
import { Helmet } from "react-helmet";
import Menu from 'Components/Menu/Menu';

function Header() {

    return (
        <>
            <Helmet>
                <title>{translation.helmet.main}</title>
            </Helmet>
            <Menu />
        </>

    );
}

export default Header;


