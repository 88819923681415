import text from '../../assets/translations/translation.json'

function translation() {

    const lang = window.location.href.split('/')[3]

    if(lang === "pl" || lang === "en") {
        return text[lang]
    } else {
        return text["pl"]
    }
}

export default translation()