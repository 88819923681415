import React from "react";

import styles from './Loader.module.css'

function Loader(props) {

    return (
        <div className={`${styles.wrapperLoader} ${props.loading ? styles.wrapperLoaderShow : ''}`}>
            <div className={styles.loader}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader;
