import React, {useEffect, useState} from "react";

import styles from './privacyPolicy.module.css'
import translation from "../../Components/Helpers/translation";
import {Helmet} from "react-helmet";
import {LoaderPage} from "../../Components/LoaderPage/loaderPage";
import {useParams} from "react-router-dom";
import Footer from "../Footer/Footer";

function PrivacyPolicy() {

    const [data,setData] = useState([])

    const { lang } = useParams()

    useEffect(() => {
        fetch(`https://api-polish.musicsources.pl/page/3?lang=${lang}`)
            .then((res) => res.json())
            .then((json) => {
                setData(json);
            })
    }, [lang]);

    return (
        <>
            <Helmet>
                <title>{translation.helmet.polityka} - {translation.helmet.main}</title>
            </Helmet>
            <LoaderPage data={data}/>
            {data ?
                <div className={styles.wrapper}>
                    <div className={styles.backgroundTitle}>
                        <h1 className={styles.mainTitle}>{translation.titles.polityka}</h1>
                    </div>
                    <div className={styles.mainWrapper} dangerouslySetInnerHTML={{__html: data.txt}}/>
                    <Footer />
                </div>
            : null}
        </>
    )
}

export default PrivacyPolicy