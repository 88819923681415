import React from 'react'
import {Link, useParams} from "react-router-dom";

import styles from '../Collections.module.css'
import translation from "../../../Components/Helpers/translation";

export const CollectionsElement = ({ collection }) => {

    const {lang} = useParams()
    let slug = require('slug')

    return (
        <div className={styles.box}>
            <Link to={`/${lang}/kolekcje/${collection.id}-${slug(collection.title)}`}
                  title={`${translation.wcagLabels.kolekcje.goToCollection} - "${collection.title}"`}
                  aria-label={`${translation.wcagLabels.kolekcje.goToCollection} - "${collection.title}"`}
            >
                {collection.image
                    ? <div className={styles.img} style={{ backgroundImage : `url("https://storage.nifc.pl/web_files/_plik/groups/groups/${collection.image}")` }}/>
                    : <div className={styles.img}>Brak Zdjęcia ID obiektu {collection.id}</div>
                }
                <div className={styles.items}>
                    <h2 className={styles.title} dangerouslySetInnerHTML={{__html: collection.title}}/>
                    <span className={styles.lead} dangerouslySetInnerHTML={{__html: collection.txt}}/>
                </div>
            </Link>
        </div>
    )
}
