import React, { useEffect } from 'react';
import {Splide, SplideSlide, SplideTrack} from '@splidejs/react-splide';
import '@splidejs/react-splide/css/core';
import "./VertcialCarousel.css";
import translation from "../Helpers/translation";


function VertcialCarousel({ galleryData, mainRef, data, forwardRef, slideActive }) {

    const goToSlide = (event, index) => {
        mainRef.current.go(index)
    }

    const keyDown = (event) => {
        if(event.key === "ArrowDown") {
            forwardRef.current.go('>')
        } else if(event.key === "ArrowUp") {
            forwardRef.current.go('<')
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', keyDown);
        return () => window.removeEventListener('keydown', keyDown);
    })

    const move = (slider,newIndex) => {
        if(slider) {
            const children = slider.root.children[1].children[0].children

            const id = slider.root.id

            let array = []

            for (let i = newIndex; i <= newIndex + 5;i++) {
                if(i + 1 < 10) {
                    array.push(`${id}-slide0${i + 1}`)
                } else {
                    array.push(`${id}-slide${i + 1}`)
                }
            }

            Array.from(children).forEach((item) => {
                if(item.classList.contains("is-visible")) {
                    item.style.backgroundImage = `url("${item.children[0].dataset.back}")`
                } else if(array.includes(item.id)) {
                    item.style.backgroundImage = `url("${item.children[0].dataset.back}")`
                }
            })
        }
    }

    return(
        <>
            <Splide hasTrack={ false } ref={forwardRef} aria-label="..." id={'VertcialCarousel'}
                onMounted={() => {move(0)}}
                onReady={(slider) => {move(slider,0)}}
                onMove={(slider,newIndex) => {move(slider,newIndex)}}
                options={{
                    direction: 'ttb', 
                    perPage: 5,
                    perMove: 5,
                    type: 'slide',
                    height  : 'calc(100vh - 120px)',
                    speed: 500,
                    gap: '28px',
                    pagination: false, 
                    wheel: true,
                    releaseWheel: true,
                    lazyLoad: "nearby",
                    preloadPages: 1,
                    cover: true,
                    updateOnMove : true,
                    start: `0`,
                    breakpoints: {
                        1400: { perPage: 4,  perMove: 4},
                    },
                    i18n: {
                        prev: translation.wcagLabels.gallery.verticalPrev,
                        next: translation.wcagLabels.gallery.verticalNext,
                        first : translation.wcagLabels.gallery.verticalPrev,
                        last : translation.wcagLabels.gallery.verticalNext,
                        slideLabel : translation.arrows.slideLabel,
                        carousel : ''
                    }
                }}
            className={"carousel"}
            >
                <div className="splide__arrows">
                    <button className={`splide__arrow splide__arrow--prev vertcialTop`} title={translation.wcagLabels.gallery.verticalPrev}/>
                    <button className={`splide__arrow splide__arrow--next verticalBottom`} title={translation.wcagLabels.gallery.verticalNext}/>
                </div>
                <SplideTrack className={"newTrack"}>
                    {galleryData.length > 0 ? (
                        galleryData.map((item, index) => {
                            return (
                                <SplideSlide
                                    id={index}
                                    key={index}
                                    className={`item ${index === slideActive ? 'itemActive' : ''}`}
                                    onClick={(event) => {goToSlide(event,index)}}
                                    aria-label={`${data.source_or_owner ? `${data.source_or_owner.name} - ` : ''}${data.standardized_title} - ${translation.wcagLabels.gallery.page} ${index + 1}`}
                                    title={`${data.source_or_owner ? `${data.source_or_owner.name} - ` : ''}${translation.wcagLabels.gallery.page} ${index + 1}`}
                                >
                                    <div
                                        data-back={item}
                                    />
                                    <div className={'counter'}>{index + 1} / {galleryData.length}</div>
                                </SplideSlide>
                            );
                        })
                    ) : null}
                </SplideTrack>
            </Splide>
        </>
    )
}
export default VertcialCarousel;