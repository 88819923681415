import React, {useEffect, useRef} from "react";
import { useFormik } from 'formik';


import PDF_pl from '../../assets/pdf/Klauzura-Informacyjna-Formularz-kontaktowy.pdf'
import PDF_en from '../../assets/pdf/Information-Clause-Contact-Form.pdf'
import translation from "../Helpers/translation";
import styles from './errorFrom.module.css'
import {useParams} from "react-router-dom";

function ErrorFrom(props) {

    const { lang } = useParams();
    const modalBackgroundRef = useRef();
    const modalRef = useRef();
    const modalContainer = useRef();
    const closeRef = useRef();
    const sendFormRef = useRef();

    const hideModal = (event) => {
        if(event.target === modalRef.current || event.target === closeRef.current) {
            props.setOpened(!props.opened)
        }
    }

    const validate = values => {
        const errors = {};

        if (!values.nameAndSurname) {
            errors.nameAndSurname = '*' + translation.galleryPage.error.modal.errorValidation.mandatoryField
        } else if (values.nameAndSurname.length <= 1) {
            errors.nameAndSurname = translation.galleryPage.error.modal.errorValidation.toShort;
        }

        if (!values.email) {
            errors.email = '*' + translation.galleryPage.error.modal.errorValidation.mandatoryField
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = '*' + translation.galleryPage.error.modal.errorValidation.emailError
        }

        if (values.errorType.length <= 0) {
            errors.errorType = '*' + translation.galleryPage.error.modal.errorValidation.mandatoryField
        }

        if (!values.content) {
            errors.content = '*' + translation.galleryPage.error.modal.errorValidation.mandatoryField
        } else if (values.content.length <= 1) {
            errors.content = translation.galleryPage.error.modal.errorValidation.toShort;
        }

        if (values.agreement === false) {
            errors.agreement = '*' + translation.galleryPage.error.modal.errorValidation.mandatoryField
        }

        return errors;
    };

    const scrollToMiddleForm = () => {

        setTimeout(() => {
            const height = (modalContainer.current.clientHeight - window.innerHeight) /2
            modalRef.current.scrollTo(0, height)
        },1)
    }

    useEffect(() => {
        if (props.opened) {
            const  focusableElements =
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
            const modal = modalRef.current;

            const firstFocusableElement = modal.querySelectorAll(focusableElements)[0];
            const focusableContent = modal.querySelectorAll(focusableElements);
            const lastFocusableElement = focusableContent[focusableContent.length - 1];

            const keyDownFunction = (e) => {
                let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

                if (!isTabPressed) {
                    return;
                }

                if (e.shiftKey) {
                    if (document.activeElement === firstFocusableElement) {
                        lastFocusableElement.focus();
                        e.preventDefault();
                    }
                } else {
                    if (document.activeElement === lastFocusableElement) {
                        firstFocusableElement.focus();
                        e.preventDefault();
                    }
                }
            }


            document.addEventListener('keydown', keyDownFunction)
            return () => document.removeEventListener('keydown' , keyDownFunction)
        } else {

        }
    },[props.opened])

    const SignupForm = () => {
        const formik = useFormik({
            initialValues: {
                nameAndSurname: '',
                institutionName: '',
                email: '',
                errorType: [],
                content: '',
                agreement: false,
            },
            validate,
            onSubmit: (values, actions) => {
                const formData = new FormData();

                formData.append("nameAndSurname", values.nameAndSurname);
                formData.append("institutionName", values.institutionName);
                formData.append("email", values.email);
                formData.append("errorType", values.errorType);
                formData.append("content", values.content);
                formData.append("agreement", values.agreement);
                formData.append("url", window.location.href)

                fetch(`https://api-polish.musicsources.pl/error_system/gallery`, {
                    method: 'POST',
                    body: formData
                })
                    .then((res) => res.json())
                    .then((json) => {
                        actions.setSubmitting(false)
                        if(json === 1) {
                            scrollToMiddleForm()
                            sendFormRef.current.classList.add(styles.formSendOpen);
                            sendFormRef.current.children[0].innerHTML = translation.galleryPage.error.modal.valid
                            actions.resetForm({
                                values: {
                                    nameAndSurname: '',
                                    institutionName: '',
                                    email: '',
                                    errorType: [],
                                    content: '',
                                    agreement: false,
                                }
                            })

                            setTimeout(() => {
                                props.setOpened(!props.opened)
                                setTimeout(() => {
                                    sendFormRef.current.classList.remove(styles.formSendOpen);
                                    sendFormRef.current.children[0].innerHTML = ""
                                },1500)
                                setTimeout(() => {
                                    modalRef.current.scrollTo(0, 0)
                                },500)
                            },3000)

                        } else {
                            sendFormRef.current.classList.add(styles.formSendOpen);
                            sendFormRef.current.children[0].innerHTML = translation.galleryPage.error.modal.invalid
                            scrollToMiddleForm()
                            setTimeout(() => {
                                sendFormRef.current.classList.remove(styles.formSendOpen);
                                sendFormRef.current.children[0].innerHTML = ""
                            },3000)
                        }

                    })
                    .catch(error => {
                        sendFormRef.current.classList.add(styles.formSendOpen);
                        sendFormRef.current.children[0].innerHTML = translation.galleryPage.error.modal.invalid
                        scrollToMiddleForm()
                        setTimeout(() => {
                            sendFormRef.current.classList.remove(styles.formSendOpen);
                            sendFormRef.current.children[0].innerHTML = ""
                        },3000)
                    });
            },
        });
        return (
            <form onSubmit={formik.handleSubmit} className={styles.form}>
                <div className={styles.title}>{translation.galleryPage.error.modal.title}</div>
                <div className={styles.subTitle}>{translation.galleryPage.error.modal.subTitle}</div>
                <div className={styles.inputWrapper}>
                    <label>
                        <p className={styles.inputTitle}>{translation.galleryPage.error.modal.inputTitle.nameAndSurname}<sup className={styles.star}>*</sup></p>
                        <input
                            className={styles.input}
                            id={"nameAndSurname"}
                            name={"nameAndSurname"}
                            type={"text"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.nameAndSurname}
                            placeholder={' '}
                        />
                    </label>
                    <div className={`${styles.error} ${formik.touched.nameAndSurname && formik.errors.nameAndSurname ? styles.errorShow : ''} `}>{formik.errors.nameAndSurname}</div>
                </div>
                <div className={styles.inputWrapper}>
                    <label>
                        <p className={styles.inputTitle}>{translation.galleryPage.error.modal.inputTitle.institutionName}</p>
                        <input
                            className={styles.input}
                            id={"institutionName"}
                            name={"institutionName"}
                            type={"text"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.institutionName}
                            placeholder={' '}
                        />
                    </label>
                </div>
                <div className={styles.inputWrapper}>
                    <label>
                        <p className={styles.inputTitle}>{translation.galleryPage.error.modal.inputTitle.email}<sup className={styles.star}>*</sup></p>
                        <input
                            className={styles.input}
                            id={"email"}
                            name={"email"}
                            type={"text"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            placeholder={' '}
                        />
                    </label>
                    <div className={`${styles.error} ${formik.touched.email && formik.errors.email ? styles.errorShow : ''} `}>{formik.errors.email}</div>
                </div>
                <div className={styles.inputWrapper}>
                    <p className={styles.inputTitle}>{translation.galleryPage.error.modal.inputTitle.errorType}<sup className={styles.star}>*</sup></p>
                    <div className={styles.checkboxItemWrapper}>
                        <label className={styles.checkboxItem}>
                            <input
                                name={"errorType"}
                                type={"checkbox"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={translation.galleryPage.error.modal.errorTypeValue["1"]}
                                checked={formik.values.errorType.includes(translation.galleryPage.error.modal.errorTypeValue["1"])}
                            />
                        </label>
                        <div className={styles.text}>{translation.galleryPage.error.modal.errorTypeValue["1"]}</div>
                    </div>
                    <div className={styles.checkboxItemWrapper}>
                        <label className={styles.checkboxItem}>
                            <input
                                name={"errorType"}
                                type={"checkbox"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={translation.galleryPage.error.modal.errorTypeValue["2"]}
                                checked={formik.values.errorType.includes(translation.galleryPage.error.modal.errorTypeValue["2"])}
                            />
                        </label>
                        <div className={styles.text}>{translation.galleryPage.error.modal.errorTypeValue["2"]}</div>
                    </div>
                    <div className={styles.checkboxItemWrapper}>
                        <label className={styles.checkboxItem}>
                            <input
                                name={"errorType"}
                                type={"checkbox"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={translation.galleryPage.error.modal.errorTypeValue["3"]}
                                checked={formik.values.errorType.includes(translation.galleryPage.error.modal.errorTypeValue["3"])}
                            />
                        </label>
                        <div className={styles.text}>{translation.galleryPage.error.modal.errorTypeValue["3"]}</div>
                    </div>
                    <div className={styles.checkboxItemWrapper}>
                        <label className={styles.checkboxItem}>
                            <input
                                name={"errorType"}
                                type={"checkbox"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={translation.galleryPage.error.modal.errorTypeValue["4"]}
                                checked={formik.values.errorType.includes(translation.galleryPage.error.modal.errorTypeValue["4"])}
                            />
                        </label>
                        <div className={styles.text}>{translation.galleryPage.error.modal.errorTypeValue["4"]}</div>
                    </div>
                    <div className={styles.checkboxItemWrapper}>
                        <label className={styles.checkboxItem}>
                            <input
                                name={"errorType"}
                                type={"checkbox"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={translation.galleryPage.error.modal.errorTypeValue["5"]}
                                checked={formik.values.errorType.includes(translation.galleryPage.error.modal.errorTypeValue["5"])}
                            />
                        </label>
                        <div className={styles.text}>{translation.galleryPage.error.modal.errorTypeValue["5"]}</div>
                    </div>
                    <div className={`${styles.error} ${formik.touched.errorType && formik.errors.errorType ? styles.errorShow : ''} `}>{formik.errors.errorType}</div>
                </div>
                <div className={styles.inputWrapper}>
                    <label>
                        <p className={styles.inputTitle}>{translation.galleryPage.error.modal.inputTitle.content}<sup className={styles.star}>*</sup></p>
                        <textarea
                            id={"content"}
                            name={"content"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.content}
                            placeholder={translation.galleryPage.error.modal.contentPlaceholder}
                            rows={4}
                        />
                    </label>
                    <div className={`${styles.error} ${formik.touched.content && formik.errors.content ? styles.errorShow : ''} `}>{formik.errors.content}</div>
                    <p className={styles.consent}>{translation.galleryPage.error.modal.agreement} <a href={lang === 'pl' ? PDF_pl : PDF_en} target={"_blank"} title={translation.titleLink.errorPdf} rel="noreferrer" aria-label={translation.titleLink.errorPdf}>{translation.galleryPage.error.modal.agreementPDF}</a></p>
                </div>
                <div className={styles.inputWrapper}>
                    <div className={`${styles.checkboxItemWrapper} ${styles.checkboxItemWrapperSmall}`}>
                        <label className={styles.checkboxItem}>
                            <input
                                id={"agreement"}
                                name={"agreement"}
                                type={"checkbox"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.agreement}
                                checked={formik.values.agreement}
                            />
                        </label>
                        <div className={styles.text}>{translation.galleryPage.error.modal.inputTitle.agreement}</div>
                    </div>
                    <div className={`${styles.error} ${formik.touched.agreement && formik.errors.agreement ? styles.errorShow : ''} `}>{formik.errors.agreement}</div>
                </div>
                <button className={styles.submitWrapper} type="submit" aria-label={translation.galleryPage.error.modal.sendForm}>
                    <span>{translation.galleryPage.error.modal.sendForm}</span>
                </button>
            </form>
        );
    };

    return (
        <>
            <div ref={modalBackgroundRef} className={`${styles.modalBackground} ${!props.opened ? styles.modalBackgroundHidden : ''}`} data-opened={props.opened} />
            <div ref={modalRef} className={`${styles.modalWrapper} ${!props.opened ? styles.modalWrapperHidden : ''}`} data-opened={props.opened} onClick={hideModal}>
                <div className={styles.modalContainer} ref={modalContainer}>
                    <div className={styles.modal}>
                        <SignupForm/>
                        <div ref={sendFormRef} className={styles.formSend}>
                            <div className={styles.formSendText}></div>
                        </div>
                        <button type={"button"} ref={closeRef} className={styles.close} onClick={hideModal}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ErrorFrom;