import React from 'react'
import {Link, useLocation, useParams} from 'react-router-dom';
import { typeOfCategoryToLink , typeOfImageToLink } from '../../../Components/Helpers/selectType'
import styles from "../CollectionList.module.css";
import translation from "../../../Components/Helpers/translation";

import defaultImg from '../../../assets/image/other/defaul-img.svg'

const CollectionListElement = ({ collection, saveScrollPosition}) => {

    const allowedTypes = ['manuscripts','musicalprint_source','convolutes'];
    let { lang } = useParams();
    let location = useLocation()

    return (
        <Link to={{ pathname: `${typeOfCategoryToLink(collection, lang)}`, state: { prevPath: location.pathname } }}
              className={styles.box}
              onClick={saveScrollPosition}
              title={`${translation.wcagLabels.kolekcje.goToObject} - "${collection.title}"`}
              aria-label={`${translation.wcagLabels.kolekcje.goToObject} - "${collection.title}"`}
        >
            {allowedTypes.includes(collection.type)
                ? collection.image
                    ? <div className={styles.img} style={{backgroundImage: `url("${typeOfImageToLink(collection)}")`}}/>
                    : <div className={styles.img} style={{backgroundImage: `url("${defaultImg}")`}}/>
                : <div className={styles.img}>Niedozwolony typ {collection.type} id obiektu {collection.id_object}</div>
            }

            <div className={styles.item}>
                <div className={styles.title}>{collection.author_or_institution}</div>
                <div className={styles.set}>{collection.title}</div>
                <div className={styles.signature}>{translation.archives.signature}: {collection.signature}</div>
                <div className={styles.signature}>{translation.archives.owners}: {collection.owners}</div>
            </div>
        </Link>
    );
}

export default CollectionListElement;
