import React, { useEffect} from 'react';
import styles from './loaderPage.module.css'
import './loaderPage.module.css'

export function LoaderPage(props) {
    useEffect(() => {
        if(props.data && document.getElementById("loader")) {
            document.getElementById("loader").style.transition = "0.4s all"
            if(props.scroll !== false) {
                window.scrollTo(0,0)
            }
            setTimeout(() => {
                document.getElementById("loader").style.opacity = "0"
                document.getElementById("loader").style.visibility = "hidden"
            },100)
        } else {
            document.getElementById("loader").style.transition = "0s all"
            document.getElementById("loader").style.opacity = "1"
            document.getElementById("loader").style.visibility = "visible"
        }
    },[props.data, props.scroll])

    return (
        <div className={styles.loader} id={"loader"}>
            <div className={styles.loaderInner}>
                <div className={`${styles.circle} ${styles.one}`}>
                    <svg width="250" height="250" viewBox="0 0 250 250">
                        <g transform="translate(0,-47)">
                            <path d="m162.74 172.02a37.5 37.5 0 0 1-37.5 37.5 37.5 37.5 0 0 1-37.5-37.5 37.5 37.5 0 0 1 37.5-37.5 37.5 37.5 0 0 1 37.5 37.5zm-60.489-74.012a77.5 77.5 0 0 1 22.989-3.4882 77.5 77.5 0 0 1 69.686 43.586m5.2325 14.077a77.5 77.5 0 0 1 2.5818 19.837 77.5 77.5 0 0 1-32.156 62.85m-12.513 7.3521a77.5 77.5 0 0 1-32.831 7.2977 77.5 77.5 0 0 1-60.737-29.362m-7.6912-11.754a77.5 77.5 0 0 1-9.0718-36.384 77.5 77.5 0 0 1 41.042-68.389m76.49-42.081a117.5 117.5 0 0 1 35.537 20.495m15.197 15.358a117.5 117.5 0 0 1 26.046 61.924m0.21644 23.207a117.5 117.5 0 0 1-114.33 106.96m-21.935-1.5541a117.5 117.5 0 0 1-39.087-13.911m-18.156-12.799a117.5 117.5 0 0 1-34.912-51.807m-4.9553-20.963a117.5 117.5 0 0 1-1.1551-16.435 117.5 117.5 0 0 1 117.5-117.5 117.5 117.5 0 0 1 18.734 1.5031" fill="none" stroke="#302682" strokeWidth="5"/>
                        </g>
                    </svg>
                </div>
                <div className={`${styles.circle} ${styles.two}`}>
                    <svg width="250" height="250" viewBox="0 0 250 250">
                        <g transform="translate(0,-47)">
                            <path transform="matrix(.26458 0 0 .26458 0 47)" d="m437.25 686.9a217.32 217.32 0 0 1-182.14-214.46 217.32 217.32 0 0 1 195.4-216.21m53.371 1.1784a217.32 217.32 0 0 1 185.88 215.04 217.32 217.32 0 0 1-199.52 216.59m174.66-530.84a368.5 368.5 0 0 1 176.04 314.25 368.5 368.5 0 0 1-41.28 169.47m-28.315 46.05a368.5 368.5 0 0 1-298.91 152.98 368.5 368.5 0 0 1-75.156-7.7454m-53.33-15.38a368.5 368.5 0 0 1-240.02-345.38 368.5 368.5 0 0 1 37.101-161.14m27.152-46.766a368.5 368.5 0 0 1 304.25-160.59 368.5 368.5 0 0 1 144.96 29.709" fill="none" stroke="#302682" strokeWidth="18.898"/>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    );
}