import React from 'react';
import { useLocation, useHistory } from "react-router-dom";
import slideDownUp from 'Components/Helpers/slideDownUp';
import queryString from "query-string";

import styles from "./InputSort.module.css";
import translation from "../../Components/Helpers/translation";

export const InputSort = ({id, owner, dataCount}) => {

    const location = useLocation();
    const history = useHistory();
    const paramSort = queryString.parse(location.search)["param"]

    const handleChangeSearchCategoryMenu = () => {
        const element = document.getElementsByClassName(styles.optionsMenu)[0];
        const wrapper = document.getElementsByClassName(styles.searchOptions)[0];
        const arrow = document.getElementsByClassName(styles.more)[0]
        if (wrapper.classList.contains(styles.searchOptionsOpened)) {
            wrapper.classList.remove(styles.searchOptionsOpened)
            arrow.classList.remove(styles.moreRotate)
        } else {
            wrapper.classList.add(styles.searchOptionsOpened)
            arrow.classList.add(styles.moreRotate)
        }
        slideDownUp(element, styles.optionsMenuOpen)
    }

    const handleChangeCategory = (event) => {
        history.push(`${location.pathname}${location.search ? '' : '/sortuj'}?param=${event.target.dataset.cat}`)
        window.location.reload(true);
        const mainText = document.getElementsByClassName(styles.text)[0];
        mainText.innerHTML = event.target.innerHTML
    }

    const selectCounter = () => {
        switch (paramSort) {
            case 'identyfikator':
                return dataCount.sum
            case 'zrodla_muzyczne':
                return dataCount.count_musical_print_sources
            case 'rekopisy':
                return dataCount.count_manuscripts
            case 'konwoluty':
                return dataCount.count_convolutes
            default:
                return dataCount.sum
        }
    }

    const onFocusHandler = () => {
        document.getElementsByClassName(styles.optionsMenu)[0].style.height = "auto"
        const arrow = document.getElementsByClassName(styles.more)[0]
        arrow.classList.add(styles.moreRotate)
    }

    const onBlurHandle = () => {
        document.getElementsByClassName(styles.optionsMenu)[0].style.height = "0px"
        const arrow = document.getElementsByClassName(styles.more)[0]
        arrow.classList.remove(styles.moreRotate)
    }

    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>
                {owner.name} ({dataCount ? `${selectCounter()} ${translation.archives.objectsList}` : ''})
            </h2>
            <div className={styles.buttonWrapper}>
                <div className={styles.sortBy}>Sortuj wg:</div>
                <div className={styles.searchOptions} onClick={handleChangeSearchCategoryMenu} title={translation.wcagLabels.lokalizacje.sort}>
                    <span className={styles.text}>{translation.archives.sort[paramSort ? paramSort : "wybierz"]}</span>
                    <div className={styles.more} />
                    <div className={`${styles.optionsMenu}`}>
                        <button type={"button"} className={`${styles.optionText}`} tabIndex={0} data-cat={"identyfikator"} onFocus={onFocusHandler} onBlur={onBlurHandle} onClick={handleChangeCategory}>{translation.archives.sort.identyfikator}</button>
                        <button type={"button"} className={`${styles.optionText}`} tabIndex={0} data-cat={"zrodla_muzyczne"} onFocus={onFocusHandler} onBlur={onBlurHandle} onClick={handleChangeCategory}>{translation.archives.sort.zrodla_muzyczne}</button>
                        <button type={"button"} className={`${styles.optionText}`} tabIndex={0} data-cat={"rekopisy"} onFocus={onFocusHandler} onBlur={onBlurHandle} onClick={handleChangeCategory}>{translation.archives.sort.rekopisy}</button>
                        <button type={"button"} className={`${styles.optionText}`} tabIndex={0} data-cat={"konwoluty"} onFocus={onFocusHandler} onBlur={onBlurHandle} onClick={handleChangeCategory}>{translation.archives.sort.konwoluty}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const SortAdvSearch = () => {
    return (
        <div className={styles.wrapperAdv}>
                <div className={styles.title}>Ilość wyników: 86</div>
                <div className={styles.box}>
                    <div className={styles.sortBy}>Sortuj wg:</div>
                    <div className={styles.sortOptions}>
                        <span className={styles.text}>identyfikator</span>
                        <div className={styles.more}/>
                    </div>
                </div>
        </div>
    )
}