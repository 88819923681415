export const apiRoutesUrls = {
    main: "https://api.nifc.pl/v2/index.php/",
    main2: "https://api-polish.musicsources.pl/",

    // Source or owner
    source_or_owner: "source_or_owner",
    search_sources_or_owners: "search_sources_or_owners",
    get_source_or_owner_by_id : "get_source_or_owner_by_id",
    sources_or_owners_items_by_id: "sources_or_owners_items_by_id",
    count_sources_or_owners_items: "count_sources_or_owners_items",
    count_source_or_owner_items: "count_source_or_owner_items",

    cms: "https://storage.nifc.pl/web_files/_plik/",

    // Source of images
    manuscripts: "manuscripts",
    convolutes: "convolutes",
    first_edition: "first_edition",

    // Images by types
    getImageManuscriptsArticleById:"https://storage.nifc.pl/web_files/_plik/museum/manuscripts",
    getImageFirstEditionsArticleById:"https://storage.nifc.pl/web_files/_plik/first_editions",
    getImageConvoluteArticleById: "https://storage.nifc.pl/web_files/_plik/convolutes",

    // Source of types
    getUrlManuscriptsArticleById: "manuscripts/get_manuscript_by_id/",
    getUrlFirstEditionsArticleById: "first_editions/get_musical_print_by_id/",
    getUrlConvoluteArticleById: "convolutes/get_convolute_by_id/",

    cmsCategoryArchivesImage: "https://storage.nifc.pl/web_files/_plik/source_or_owner",

    // mainPage

    mainPage: 'groups/get_items_by_id_group_random/',

    // collection

    collection : 'groups/get_groups_by_id_workspace/',

    // collectionList

    collectionList : 'groups/get_items_by_id_group_popc2/',
    collectionName : 'groups/get_by_id/',

    // search

    search : 'Search_engine_popc2/search/',
    searchPopc1 : 'Search_engine_popc2/search_popc1_test/'
};

