import React, { useEffect, useState } from 'react';

import { CollectionsElement } from './CollectionsElement/CollectionsElement';

import { LoaderPage } from '../../Components/LoaderPage/loaderPage';
import styles from './Collections.module.css';
import { apiRoutesUrls } from "../../Api/ApiNames";
import translation from "../../Components/Helpers/translation";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import Footer from "../Footer/Footer";

function Collections() {

	const [data, setData] = useState([])
	const {lang} = useParams()

	useEffect(() => {
		fetch(`${apiRoutesUrls.main}${apiRoutesUrls.collection}7?lang=${lang}`)
			.then((res) => res.json())
			.then((json) => {
				setData(json);
			})
	},[lang])

	return (
		<>
			<Helmet>
				<title>{translation.helmet.kolekcje} - {translation.helmet.main}</title>
			</Helmet>
			<LoaderPage data={data}/>
			{data ?
				<div className={styles.wrapper}>
					<div className={styles.backgroundTitle}>
						<h1 className={styles.mainTitle}>{translation.titles.collections}</h1>
					</div>
					<div className={styles.container}>
						{data.map((collection, index) => {
							return (
								<CollectionsElement
									key={`collection-${index}`}
									collection={collection}
									translation={translation}
								/>
							);
						})}
					</div>
					<Footer/>
				</div>
			: null }
		</>
	);      
}

export default Collections;


