import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { apiRoutesUrls } from 'Api/ApiNames';

import styles from "./ArchiveList.module.css";
import { InputSort } from "Atoms/Sort/InputSort";
import Loader from '../../Components/Loader/Loader';
import { LoaderPage } from '../../Components/LoaderPage/loaderPage';
import ArchiveElement from './ArchiveListElement/ArchiveListElement';
import translation from "../../Components/Helpers/translation";
import queryString from "query-string";
import {getDataApi2} from "../../Api/ApiHelpers";
import {Helmet} from "react-helmet";
import Footer from "../Footer/Footer";

const ArchiveList = () => {

    let {id} = useParams();
    id = parseInt(id);

    let limit = 10;

    const [data, setData] = useState(null);
    const [dataCount, setDataCount] = useState(null)
    const [more, setMore] = useState(false)
    const [moreLoading, setMoreLoading] = useState(false)
    const [owner, setOwner] = useState("")
    const [counter, setCounter] = useState(0)
    const [historyCounter,] = useState(sessionStorage.getItem("historyCounter") ? parseInt(sessionStorage.getItem("historyCounter")) : null)
    const location = useLocation();
    const {lang} = useParams()
    const paramSort = queryString.parse(location.search)["param"]

    const saveScrollPosition = () => {
        const position =  window.scrollY
        const newCounter = counter + 1
        sessionStorage.setItem('position', position.toString());
        sessionStorage.setItem('historyCounter',  newCounter.toString());
    }

    useEffect(() => {
        const position = sessionStorage.getItem('position')
        if(data && position) {
            window.scroll(0, parseInt(position))
            sessionStorage.removeItem('position')
            sessionStorage.removeItem('historyCounter')
        }
    },[data])

    useEffect(() => {

        const buildParams = () => {
            const formData = new FormData();

            if (paramSort === "identyfikator") {
                formData.append('data', JSON.stringify({"order": 2}))
            } else if (paramSort === "rekopisy") {
                formData.append('data', JSON.stringify({"type": 2}))
            } else if (paramSort === "zrodla_muzyczne") {
                formData.append('data', JSON.stringify({"type": 14}))
            } else if (paramSort === "konwoluty") {
                formData.append('data', JSON.stringify({"type": 19}))
            } else {
                return formData
            }

            return formData
        }

        if (counter === 0) {
            if(historyCounter > 1) {
                setCounter(historyCounter - 1)
            }
            const categoryArchivesApiUrl = `${apiRoutesUrls.source_or_owner}/${apiRoutesUrls.sources_or_owners_items_by_id}/${id}/${limit * counter}/${historyCounter ? historyCounter * limit : limit}?lang=${lang}`;
            fetch(`${apiRoutesUrls.main}${categoryArchivesApiUrl}`, {method: 'POST', body: buildParams()})
                .then((res) => res.json())
                .then((json) => {
                    setData(json);
                    if(historyCounter) {
                        if (json.length < historyCounter * limit) {
                            setMore(false)
                        } else {
                            setMore(true)
                        }
                    } else {
                        if (json.length < limit) {
                            setMore(false)
                        } else {
                            setMore(true)
                        }
                    }
                })

            fetch(`${apiRoutesUrls.main}${apiRoutesUrls.source_or_owner}/${apiRoutesUrls.get_source_or_owner_by_id}/${id}/?lang=${lang}`)
                .then((res) => res.json())
                .then((json) => {
                    setOwner(json)
                })
        }
    }, [id, counter, limit, paramSort, lang, historyCounter]);

    useEffect(() => {

        const buildParams = () => {
            const formData = new FormData();

            if (paramSort === "identyfikator") {
                formData.append('data', JSON.stringify({"order": 2}))
            } else if (paramSort === "rekopisy") {
                formData.append('data', JSON.stringify({"type": 2}))
            } else if (paramSort === "zrodla_muzyczne") {
                formData.append('data', JSON.stringify({"type": 14}))
            } else if (paramSort === "konwoluty") {
                formData.append('data', JSON.stringify({"type": 19}))
            } else {
                return formData
            }

            return formData
        }

        if (moreLoading && more) {
            const categoryArchivesApiUrl = `${apiRoutesUrls.source_or_owner}/${apiRoutesUrls.sources_or_owners_items_by_id}/${id}/${counter * limit}/${limit}/?api_key=6fa111f699ace599f5b7128273932248&lang=${lang}`;
            fetch(`${apiRoutesUrls.main}${categoryArchivesApiUrl}`,{method: 'POST', body: buildParams()})
                .then((res) => res.json())
                .then((json) => {
                    setMoreLoading(false)
                    let oldData = data
                    oldData = [...oldData, ...json]
                    setData(oldData);
                    if (json.length < limit) {
                        setMore(false)
                    } else {
                        setMore(true)
                    }
                })
        }
    }, [id, counter, limit, moreLoading, more, data, paramSort, lang]);

    useEffect(() => {
        const categoryArchivesCountApiUrl = `${ apiRoutesUrls.source_or_owner} / ${apiRoutesUrls.count_source_or_owner_items } / ${id}`;
        getDataApi2(categoryArchivesCountApiUrl, setDataCount);
    }, [lang, id]);

    return (
        <>
            <Helmet>
                <title>{translation.helmet.lokalizacjeTitleList} {`"${owner.name}"`} - {translation.helmet.main}</title>
            </Helmet>
            <LoaderPage data={data} scroll={false}/>
            {data ?
                <div className={styles.wrapper}>
                    <div className={styles.backgroundTitle}>
                        <h1 className={styles.mainTitle}>{translation.titles.archives}</h1>
                    </div>
                    <div className={styles.containerList}>
                        <div className={styles.boxSort}>
                            <InputSort id={id} owner={owner} dataCount={dataCount}/>
                        </div>
                        <div className={styles.container}>
                            {data.map((article, index) => {
                                return (
                                    <ArchiveElement
                                        key={`archive-article-${index}`}
                                        article={article}
                                        translation={translation}
                                        saveScrollPosition={saveScrollPosition}
                                    />
                                );
                            })}
                        </div>
                        <Loader loading={moreLoading}/>
                    </div>
                    {more ?
                        <button type={"button"} className={styles.more}
                            onClick={() => {
                                setMoreLoading(true)
                                setCounter(counter + 1);
                                document.getElementsByClassName(styles.container)[0].children[counter * limit + 9].focus()
                            }}
                            title={translation.wcagLabels.lokalizacje.showMore}
                        >
                            {translation.other.showMore}
                        </button>
                        : null
                    }
                    <Footer/>
                </div>
            : null}
        </>

    );
};

export default ArchiveList;


