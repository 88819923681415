import React, {useEffect, useRef, useState} from "react";

import styles from './slideUpDownContainer.module.css'
import usePrevious from "../usePrevious";

function SlideUpDownContainer(props) {

    const ref = useRef()
    const prevClosed = usePrevious(props.closed)
    const [, setHeight] = useState()

    useEffect(() => {

        const startTransition = (prevHeight) => {
            let endHeight = '0px'

            if (!props.closed) {
                ref.current.classList.remove(styles.closed)
                ref.current.style.height = 'auto'
                endHeight = getComputedStyle(ref.current).height
            }

            if (parseFloat(endHeight).toFixed(2) !== parseFloat(prevHeight).toFixed(2)) {
                ref.current.classList.add(styles.transitioning)
                ref.current.style.height = prevHeight
                setHeight(ref.current.offsetHeight)
                ref.current.style.transitionProperty = 'height'
                ref.current.style.height = endHeight
            }
        }

        if (prevClosed !== undefined) {
            if(props.closed !== prevClosed) {
                startTransition(ref.current.offsetHeight + 'px')
            }
        }

    },[props.closed, prevClosed])

    const endTransition = () => {
        ref.current.classList.remove(styles.transitioning)
        ref.current.style.transitionProperty = 'none'
        ref.current.style.height = props.closed ? '0px' : 'auto'

        if (props.closed) {
            ref.current.classList.add(styles.closed)
        }
    }

    const handleTransitionEnd = (event) => {
        if ((event.target === ref.current) && (event.propertyName === 'height')) {
            endTransition()
        }
    }

    return (
        <div ref={ref} onTransitionEnd={handleTransitionEnd} className={`${styles.reactSlidedown} ${props.closedStart ? styles.closed : ''} `} style={{height : `${props.closedStart ? '0' : 'auto'}`}}>
            {props.children}
        </div>
    )
}

export default  SlideUpDownContainer