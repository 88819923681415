import React from 'react'
import {Link, useLocation, useParams} from 'react-router-dom';
import { typeOfCategoryToLink , typeOfImageToLink } from '../../../Components/Helpers/selectType'
import styles from "../ArchiveList.module.css";

const ArchiveElement = ({ article, translation, saveScrollPosition }) => {

    const {lang} = useParams()
    let location = useLocation()

    return (
        <Link to={{ pathname: `${typeOfCategoryToLink(article, lang)}`, state: { prevPath: location.pathname } }}
              className={styles.box}
              onClick={saveScrollPosition}
              title={`${translation.wcagLabels.lokalizacje.goToObject} - "${article.standardized_title}"`}
              aria-label={`${translation.wcagLabels.lokalizacje.goToObject} - "${article.standardized_title}"`}
        >
            <div className={styles.img} style={{backgroundImage: `url("${typeOfImageToLink(article)}")`}}/>
            <div className={styles.item}>
                <div className={styles.title}>{article.author_or_institution}</div>
                <div className={styles.set}>{article.standardized_title}</div>
                <div className={styles.signature}>{translation.archives.signature}: {article.signature}</div>
                <div className={styles.signature}>{translation.archives.owners}: {article.owners}</div>
            </div>
        </Link>
    );
}

export default ArchiveElement;
