import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Header from "../Header/Header";
import MainPage from "../MainPage/MainPage";
import Collections from "../Collections/Collections";
import About from "../About/About";
import AdvancedSearch from "Containers/AdvancedSearch/AdvancedSearch";
import AdvancedSearchPopc1 from "Containers/AdvancedSearchPopc1/AdvancedSearchPopc1";
import GalleryPage from "Containers/GalleryPage/GalleryPage";
import Archives from "../Archives/Archives";
import ArchiveList from "../ArchiveList/ArchiveList";
import CollectionList from "../CollectionList/CollectionList";
import ArchiveShow from "../ArchiveShow/ArchiveShow";
import ErrorSite from "../ErrorSite/errorSite";
import AvailabilityDeclaration from "../AvailabilityDeclaration/availabilityDeclaration";
import PrivacyPolicy from "../PrivacyPolicy/privacyPolicy";
import Chopiniana from "Containers/Chopiniana/Chopiniana";

const App = () => {

    const lang = window.location.href.split('/')[3]

    return (
        <>
            <Switch>
                <Route exact path={"/:lang(pl|en)"}>
                    <Header />
                    <MainPage />
                </Route>

                <Route exact path={"/:lang(pl|en)/lokalizacje"}>
                    <Header />
                    <Archives />
                </Route>

                <Route exact path={"/:lang(pl|en)/lokalizacje/:id"}>
                    <Header />
                    <ArchiveList />
                </Route>

                <Route exact path={"/:lang(pl|en)/lokalizacje/:id/szczegoly"}>
                    <Header />
                    <ArchiveShow />
                </Route>

                <Route exact path={"/:lang(pl|en)/lokalizacje/:id/:params"}>
                    <Header />
                    <ArchiveList />
                </Route>

                <Route exact path={"/:lang(pl|en)/lokalizacje/galeria/:type/:id"}>
                    <GalleryPage />
                </Route>

                <Route exact path={"/:lang(pl|en)/lokalizacje/galeria/:type(rekopisy|druki-muzyczne|klocki-introligatorskie)/:id/:page"}>
                    <GalleryPage />
                </Route>

                <Route exact path={"/:lang(pl|en)/lokalizacje/galeria/:type(rekopisy|druki-muzyczne|klocki-introligatorskie)/:id/:page/:children"}>
                    <GalleryPage />
                </Route>

                <Route exact path={"/:lang(pl|en)/kolekcje"}>
                    <Header />
                    <Collections />
                </Route>

                <Route exact path={"/:lang(pl|en)/kolekcje/:id"}>
                    <Header />
                    <CollectionList />
                </Route>

                <Route exact path={"/:lang(pl|en)/o-projekcie"}>
                    <Header />
                    <About />
                </Route>

                {/* <Route exact path={"/:lang(pl|en)/chopiniana"}>
                    <Header />
                    <Chopiniana />
                </Route> */}

                <Route exact path={"/:lang(pl|en)/szukaj/:params"}>
                    <Header />
                    <AdvancedSearch />
                </Route>

                <Route exact path={"/:lang(pl|en)/szukajpopc1/:params"}>
                    <Header />
                    <AdvancedSearchPopc1 />
                </Route>

                <Route exact path={"/:lang(pl|en)/deklaracja-dostepnosci"}>
                    <Header />
                    <AvailabilityDeclaration />
                </Route>

                <Route exact path={"/:lang(pl|en)/polityka-prywatnosci"}>
                    <Header />
                    <PrivacyPolicy />
                </Route>

                <Route exact path={"/:lang(pl|en)/error"}>
                    <Header />
                    <ErrorSite />
                </Route>

                <Route exact path={"/"}>
                    <Redirect to="/pl" />
                </Route>

                <Route exact path={"*"}>
                    {lang === 'pl' || lang === 'en'
                        ? <Redirect to={`/${lang}/error`} />
                        : <Redirect to={'/pl'} />
                    }
                </Route>
            </Switch>
        </>
    );
};

function Builder() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default Builder;
