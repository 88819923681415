import React, { useState, useEffect } from "react";
import {useParams} from "react-router-dom";

import styles from './scrolledLogos.module.css'
import logoFundusze from "../../assets/image/footer/fundusze.png";
import logoFundusze_en from "../../assets/image/footer/fundusze_en.png";
import logoPolska from "../../assets/image/footer/polska.png";
import logoPolska_en from "../../assets/image/footer/polska_en.png";
import logoMinisterstwo from "../../assets/image/footer/MKiDN_pl_2023.png";
import logoMinisterstwo_en from "../../assets/image/footer/MKiDN_en_2023.png";
import logoUe from "../../assets/image/footer/UE-PL.svg";
import logoUe_en from "../../assets/image/footer/UE-EN.svg";
import Cookie from "../../Containers/Cookie/Cookie";
import translation from "../Helpers/translation";

function  ScrolledLogos(props) {

    const { lang } = useParams()
    const [scrollPos, setScrollPos] = useState(0);
    const [oldScroll, setOldScroll] = useState(0)
    const [isClosed, setIsClosed] = useState(false);

    useEffect(() => {

        const toggleBottom = () => {
            setScrollPos(window.scrollY);
            setOldScroll(scrollPos)
        }

        window.addEventListener("scroll", toggleBottom, false);
        return () => window.removeEventListener("scroll", toggleBottom)
    },[scrollPos])

    useEffect(() => {
        if (scrollPos > 200 && scrollPos > oldScroll) {
            setIsClosed(true)
        } else {
            setIsClosed(false)
        }
    },[scrollPos, oldScroll])

    return (
        <div>
            <Cookie scrolledLogosClosed={isClosed}/>
            <div className={`${styles.logosWrapper} ${ isClosed ? styles.logosWrapperClosed : ''} ${props.position ? styles.positionRelative : ''}`} id={"scrolledLogos"}>
                <div className={`${styles.logosPL} ${lang === "en" ? styles.logosEN : ''} `}>
                    <div role="img" aria-label={translation.wcagLabels.footer.img1} className={`${styles.logo} ${styles.logo1}`} style={{backgroundImage: `url("${lang === "pl" ? logoFundusze : logoFundusze_en}")`}}/>
                    <div role="img" aria-label={translation.wcagLabels.footer.img2} className={`${styles.logo} ${styles.logo2}`} style={{backgroundImage: `url("${lang === "pl" ? logoPolska : logoPolska_en}")`}}/>
                    <div role="img" aria-label={translation.wcagLabels.footer.img3} className={`${styles.logo} ${styles.logo3}`} style={{backgroundImage: `url("${lang === "pl" ? logoMinisterstwo : logoMinisterstwo_en}")`}}/>
                    <div role="img" aria-label={translation.wcagLabels.footer.img4} className={`${styles.logo} ${styles.logo4}`} style={{backgroundImage: `url("${lang === "pl" ? logoUe : logoUe_en}")`}}/>
                </div>
            </div>
        </div>
    )
}

export default ScrolledLogos;